import React, { useState } from "react";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { servicePut } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import toast from "react-hot-toast";

const EditAssignmentModal = ({ setopen,  assignment, getAssignment }) => {
  const [assignmentDetail, setAssignmentDetail] = useState(assignment);

  // const user = useSelector(profileState);
  const dispatch = useDispatch();

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch(setLoadingTrue());
    setopen(false);
   
    try {
      const { data } = await servicePut(
        `mentor/mentor-api/v1/assignment?id=${assignment?._id}`,
        assignmentDetail,
        axios.defaults.headers.common
      );
      // toast.success("Assignment Updated");
      await getAssignment();
    } catch (error) {
      toast.error(error.message);
    } finally {

      dispatch(setLoadingFalse());
    }
  };
  const d = new Date(assignmentDetail?.dueDate);
  return (
    <>
      <form onSubmit={handleClick}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>Edit Assignment </h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setopen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for the Assignment
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Name</h6>
              <input
                type="text"
                required
                value={assignmentDetail?.name}
                onChange={(e) => {
                  setAssignmentDetail({
                    ...assignmentDetail,
                    name: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the name of the assignment"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Description</h6>
              <textarea
                required
                value={assignmentDetail?.description}
                onChange={(e) => {
                  setAssignmentDetail({
                    ...assignmentDetail,
                    description: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Due Date</h6>
              <input
                type="date"
                required
                value={d.toISOString().substr(0, 10)}
                min={new Date().toISOString().substr(0, 10)}
                onChange={(e) => {
                  setAssignmentDetail({
                    ...assignmentDetail,
                    dueDate: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the URL of the video lecture"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default EditAssignmentModal;
