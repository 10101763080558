import axios from "axios";
import { setDefaultOptions } from "date-fns";
import { useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { profileState } from "../../redux/features/auth/selectors";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import { serviceGet } from "../../utils/api";

const AddResourceModal = ({
  batch,
  day,
  setopen,
  getSessions,
  setRerender,
}) => {
  const [ResourceDetail, setResourceDetail] = useState({
    title: null,
    description: null,
    file: null,
  });

  const user = useSelector(profileState);
  const dispatch = useDispatch();
  const handleClick = async (event) => {
    event.preventDefault();
    setopen(false);
    setRerender((rerender) => !rerender);
    dispatch(setLoadingTrue());
    const { title, description, file } = ResourceDetail;
    try {
      const fileSize = file.size;
      if (fileSize > 1.5e7) {
        toast.error("Maximum file size is 15MB");
        return;
      }
      const type = file.type.split("/")[1];
      if (type == "pdf" || type == "zip") {
        const { destinationUrl } = await serviceGet(
          `mentor/mentor-api/v1/addasset/geturl?type=${"." + type}`
        );
        await fetch(destinationUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });
        const url = destinationUrl.split("?")[0];
        if (url) {
          const { data } = await serviceGet(
            `mentor/mentor-api/v1/addasset?title=${title}&description=${description}&destinationUrl=${url}&batch=${batch}&day=${day}&type=${type}`,
            axios.defaults.headers.common
          );
        }
        // toast.success("Asset Added Succesfully");
      } else {
        toast.error("Only pdf and zip files are allowed");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  return (
    <>
      <form onSubmit={handleClick}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>New Resource</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setopen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for the Resource
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Title</h6>
              <input
                type="text"
                required
                value={ResourceDetail.title}
                onChange={(e) => {
                  setResourceDetail({
                    ...ResourceDetail,
                    title: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the title of the Resource"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Description</h6>
              <textarea
                required
                value={ResourceDetail.description}
                onChange={(e) => {
                  setResourceDetail({
                    ...ResourceDetail,
                    description: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the Resource"
              />
            </div>
            <div className="mb-2">
              {/* <h6 className="text-lg text-gray-600">URL</h6> */}
              <input
                type="file"
                required
                value={ResourceDetail.url}
                onChange={(e) => {
                  setResourceDetail({
                    ...ResourceDetail,
                    file: e.target.files[0],
                  });
                }}
                className="w-full rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500 file:bg-purple-600 file:rounded-lg file:px-2 file:cursor-pointer file:py-1 file:text-white file:border-none"
                placeholder="Enter the URL of the Resource"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export default AddResourceModal;
