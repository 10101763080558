import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import {FiRadio} from "react-icons/fi";
import {IoMdLink,IoMdAttach} from "react-icons/io";
import toast from 'react-hot-toast';
import Modal from '../modal/modal';
import AddDayModal from '../addDay/AddDay';
import EditWeekModal from '../editWeek/EditWeek';

import { loadBatchId, loadDayId, loadWeeksOpened } from '../../redux/features/options/selectors';
import { setDayId, setWeeksOpened } from '../../redux/features/options/slice';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const WeekDropdown = ({key, week, batch, getBatch}) => {
    const batchId = useSelector(loadBatchId);
    const classSyllabus = useSelector(loadDayId);
    const [isList, setIsList] = useState(false);
    const [open, setOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    
    const weeksOpened = useSelector(loadWeeksOpened);
    const dispatch = useDispatch();

    const handleClick = () =>{
        dispatch(setWeeksOpened(week._id))
        setIsList(!isList)
    }

    useEffect(()=>{
        if(weeksOpened.includes(week._id))
            setIsList(true)
        else
            setIsList(false)
    },[])

  return (
    <>
        <Modal state={{open}}>
            <AddDayModal setOpen={setOpen} batch={batch} week={week} getBatch={getBatch}/>
        </Modal>
        <Modal state={{open: editOpen}}>
            <EditWeekModal setOpen={setEditOpen} prev={week} weekId={week._id} getBatch={getBatch} />
        </Modal>
        <div>
          <div onClick={handleClick} className="w-full p-4  text-md font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer">
                {week?.name}
                <div className='flex justify-center items-center gap-2'>
                    <button className='bg-btn rounded-md text-white p-2' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        setOpen(!open)
                    }}>
                        Add Day
                    </button>
                    <button className='bg-btn rounded-md text-white p-2' onClick={(e)=>{
                        e.preventDefault()
                        e.stopPropagation()
                        setEditOpen(!editOpen)
                    }}>
                        Edit
                    </button>
                    {isList ? (
                        <div>
                            <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z" fill="#1F2937" />
                            </svg>
                        </div>
                    ) : (
                        <div>
                            <svg width={10} height={6} viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z" fill="#1F2937" />
                            </svg>
                        </div>
                    )}
                </div>
            </div>
            {isList && (
                <div className="w-full  px-4 ">
                  
                      {week?.days.length!==0 ?
                          week?.days?.map((day)=>(
                              <div key={day?._id} className={`${classSyllabus===day?._id && "border-y-2 border-gray-400 py-2 "} my-2 ml-10 `}>
                                  <div onClick={()=>{
                                      dispatch(setDayId(day?._id))
                                  }} className={`${classSyllabus===day?._id && "border-l-4 border-purple-700 flex  items-center"} `}>

                                        <Link key={day?._id} to={`/syllabus/day`} className="w-full text-sm  ml-2 ">
                                            <div className={`${classSyllabus===day?._id && "text-purple-700"} font-semibold  mb-2`}>
                                            {day?.name}
                                            </div>
                                            <div className='text-gray-500 flex gap-2 items-center'>
                                                <div className='flex items-center gap-2'>
                                                        <FiRadio size="1.1rem"/>
                                                        <p>{day.sessions.length}</p> 
                                                </div>
                                            </div>
                                            
                                        </Link>
                                  </div>

                              </div>
                    
                          ))
                          :
                          <div className='font-semibold text-center'>
                              No days added !
                          </div>
                      }
                </div>
            )}
            <style>
                {` .checkbox:checked + .check-icon {
                display: flex;
            }`}
            </style>

        </div>
    </>
  )
}

export default WeekDropdown