import { useNavigate } from "react-router-dom";
import auth from "./RouteProtection/auth";
import img from "../../assets/images/ICON.svg";
export default function ForgetPass() {

  const navigate = useNavigate();

  const handleSubmit = (event) =>{
    event.preventDefault();
    const form_data = new FormData(event.target);
    let values = {};
    form_data.forEach(function (value, key) {
        values[key] = value;
    });

    auth.forgotPassword(()=>navigate('/'), values);
  }

    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
        <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div>
              <img
                className="mx-auto h-12 w-auto"
                src={img}
                alt="Workflow"
              />
              <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Forgot Password ?</h2>
              
            </div>
            {/* <p className="text-gray-500 font-bold" >Sign in with</p>
            <div className="flex justify-around" >
                <button className="border py-1 border-gray-300 w-1/4 rounded-lg">fb</button>
                <button className="border py-1 border-gray-300 w-1/4 rounded-lg">github</button>
                <button className="border py-1 border-gray-300 w-1/4 rounded-lg">google</button>
            </div>
            <p className="text-gray-500 text-center " >Or continue with</p> */}
            <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
              <div className="rounded-md shadow-sm -space-y-px">
                <div className="mb-6">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
              
              </div>
  
              <div>
                <button
                  type="submit"
                  className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                    <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                  </span> */}
                  Send Link
                </button>
              </div>
            </form>
          </div>
        </div>
      </>
    )
  }