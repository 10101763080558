import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import { serviceGet, servicePost } from "../../../utils/api";
import { deleteHeader, setHeader } from "../../../utils/header";

const initialState = {
  token: null,
  isAuthenticated: false,
  user: null,
  isReady: false,
};

export const login = createAsyncThunk("auth/login", async (values) => {
  try {
    const res = await servicePost("auth/auth-api/v1/login?type=mentor", {
      ...values,
    });
    const {
      data: { user, token },
      message,
      success,
    } = res;
    if (success) {
      const { firstName = "" } = user;
      toast.success(`Hey ${firstName} Welcome back`, {
        duration: 4000,
      });
      // store token
      localStorage.setItem("token", token);
      // axios.defaults.headers.common['auth'] = `bearer ${token}`;
      setHeader("auth", `bearer ${token}`);
      return {
        token,
        user,
        isAuthenticated: true,
      };
    }
    toast.error(message, {
      duration: 4000,
    });
    return {
      token: null,
      user: null,
      isAuthenticated: false,
    };
  } catch (error) {
    // delete axios.defaults.headers.common['auth'];
    deleteHeader("auth");
    deleteHeader();
    console.log(error);
  }
});

export const loadUser = createAsyncThunk("auth/loadUser", async () => {
  try {
    const token = localStorage.getItem("token");
    const {
      data: { user },
    } = await serviceGet(
      `auth/auth-api/v1/verifyAuthToken?u=mentor&token=${token}`
    );
    if (user) {
      //    axios.defaults.headers.common['auth'] = `bearer ${token}`;
      setHeader("auth", `bearer ${token}`);
      return {
        token,
        user,
        isAuthenticated: true,
        isReady: true,
      };
    }
    return {
      token: null,
      user: null,
      isAuthenticated: false,
      isReady: true,
    };
  } catch (error) {
    // delete axios.defaults.headers.common['auth'];
    deleteHeader("auth");
    return {
      token: null,
      user: null,
      isAuthenticated: false,
      isReady: true,
    };
  }
});

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state, action) {
      localStorage.removeItem("token");
      // delete axios.defaults.headers.common['auth'];
      deleteHeader("auth");
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.fulfilled, (state, action) => {
        const { token, user, isAuthenticated } = action.payload;
        state.token = token;
        state.user = user;
        state.isAuthenticated = isAuthenticated;
        state.isReady = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.token = null;
        state.user = null;
        state.token.isAuthenticated = false;
        state.isReady = true;
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        const { token, user, isAuthenticated } = action.payload;
        state.token = token;
        state.user = user;
        state.isAuthenticated = isAuthenticated;
        state.isReady = true;
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.token = null;
        state.user = null;
        state.isAuthenticated = false;
        state.isReady = true;
      });
  },
});

// export the  action
export const { logout } = authSlice.actions;

export default authSlice.reducer;
