import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { AiFillCalendar } from "react-icons/ai";
import { FaRegCopy } from "react-icons/fa";
import { FiRadio } from "react-icons/fi";

import Modal from "../modal/modal";
import EditSessionModal from "../editSession/EditSession";
import { serviceDelete } from "../../utils/api";
import { useDispatch } from "react-redux";
import {
  setLoadingTrue,
  setLoadingFalse,
} from "../../redux/features/loading/slice";
import ConfirmationMeeting from "../confirmation/ConfirmationMeeting";

const ModuleResource = ({
  topic = "",
  description = "",
  date = "",
  meeting,
  id,
  getSessions,
}) => {
  const [open, setOpen] = useState(false);
  const [editModal, seteditModal] = useState(true);
  const dispatch = useDispatch();
  console.log(date);
  const dt = new Date(
    new Date(meeting.startTime).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    })
  );
  const dt_e = new Date(
    new Date(meeting.startTime).toLocaleString("en-US", {
      timeZone: "Asia/Calcutta",
    })
  );
  dt_e.setHours(dt.getHours() + 2);
  const currentDate = new Date();

  const handleEdit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    seteditModal(true);
    setOpen(true);
  };
  // const {id : batchId} = useSelector(loadBatchId());
  const handleDelete = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    try {
      dispatch(setLoadingTrue());
      const { success, message, data } = await serviceDelete(
        `mentor/mentor-api/v1/session?sessionId=${id}`,
        axios.defaults.headers.common
      );
      dispatch(setLoadingFalse());
      if (success) {
        // toast.success(message);
        dispatch(setLoadingTrue());
        await getSessions();
        dispatch(setLoadingFalse());
      } else toast.error(message);
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  const handleCopy = (event) => {
    event.preventDefault();
    event.stopPropagation();
    navigator.clipboard.writeText(meeting.joinUrl);
    // toast.success("Copied!!!");
  };
  return (
    <>
      <Modal state={{ open }}>
        {editModal ? (
          <EditSessionModal
            platform={meeting.platform}
            topic={topic}
            agenda={description}
            startTime={dt.toTimeString().split(" ")[0].substring(0, 5)}
            date={dt.toISOString().split("T")[0]}
            setOpen={setOpen}
            id={id}
            getSessions={getSessions}
          />
        ) : (
          <ConfirmationMeeting
            setOpen={setOpen}
            platform={meeting.platform}
            url={meeting.joinUrl}
            meetingNumber={meeting.meetingNumber}
            name={topic}
            date={meeting.startTime}
          />
        )}
      </Modal>
      <div className="bg-white rounded-lg flex px-4 py-8 justify-around  my-4">
        <div className="w-3/4 flex-wrap ">
          <div className="flex gap-4 items-center mb-4">
            <FiRadio size="1.1rem" />

            <div className="font-semibold ">{topic}</div>
          </div>
          <div>{description}</div>
          <div className="flex items-center gap-2 mt-4">
            <AiFillCalendar />
            Scheduled for {dt.toDateString()} {dt.toLocaleTimeString()}
          </div>
          <div className="flex items-center gap-2 mt-4 flex-wrap">
            Join Link :- {meeting.joinUrl}
            <button className="text-blue-900" onClick={handleCopy}>
              <FaRegCopy />
            </button>
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          <div className=" flex flex-col sm:flex-row justify-center align-start">
            {currentDate <= dt_e ? (
              <>
                <button
                  className="h-10 px-5 m-2 text-red-100 transition-colors duration-150 bg-red-700 rounded-lg focus:shadow-outline hover:bg-red-800 cursor-pointer"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  className="bg-btn h-10  m-2 rounded-md text-white p-2 cursor-pointer hover:bg-blue-800"
                  onClick={handleEdit}
                >
                  Edit
                </button>
                <button
                  onClick={() => {
                    setOpen(true);
                    seteditModal(false);
                  }}
                  className="bg-btn text-center h-10  m-2 rounded-md text-white p-2 cursor-pointer hover:bg-blue-800"
                >
                  Start
                </button>
              </>
            ) : (
              <div className="p-4 h-14 rounded-full bg-blue-700 text-white">
                Expired
              </div>
            )}
          </div>
          <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
          className="opts"
        >{
          meeting?.platform=='gmeet' ?
          <img
            style={{
              border:  "3px solid dodgerblue" ,
              padding: "10px",
              borderRadius: "10px",
            }}
          height={40}
            width={50}
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Google_Meet_icon_%282020%29.svg/1024px-Google_Meet_icon_%282020%29.svg.png?20201017172050"
            alt=""
          />
          :
          <img
            style={{
              border: "3px solid dodgerblue",
              padding: "10px",
              borderRadius: "10px",
            }}
           
            height={50}
            width={60}
            src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg"
            alt=""
          />}
        </div>
        </div>
      </div>
    </>
  );
};

export default ModuleResource;
