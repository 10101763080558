import React, { useState } from 'react'
import { servicePost } from '../../utils/api'
import toast from 'react-hot-toast';
import { loadBatchId } from '../../redux/features/options/selectors';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Modal from '../../components/modal/modal';
import AddMessage from '../../components/AddMessage/AddMessage';
import Message from '../../components/Message/Message';

const Messages = () => {
    const [modalOpen, setmodalOpen] = useState(false)
  
  return (
    <>
    {/* modal for adding new messages */}
        <Modal state={{open: modalOpen}}>
            <AddMessage setOpen={setmodalOpen}/>
        </Modal>
        {/* container */}
        <div className='p-4'>
            <div className='absolute right-4'>
                <button onClick={()=>setmodalOpen(true)} className='bg-[#6422CD] text-white py-3 px-6 rounded-md'>Compose</button>
            </div>
            <Message/>
        </div>
    </>
  )
}

export default Messages;