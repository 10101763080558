import React, { useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setLoadingTrue, setLoadingFalse } from "../../redux/features/loading/slice";
import { servicePut } from "../../utils/api";

const EditWeekModal = ({ prev, setOpen, weekId, getBatch }) => {
  const [week, setWeek] = useState({
    name: prev?.name,
    description: prev?.description
  });

  const dispatch = useDispatch();

  const handleClick = async(e) => {
    e.preventDefault();
    setOpen(false);
    try {
      dispatch(setLoadingTrue())
      const {success, message, data} = await servicePut(`mentor/mentor-api/v1/syllabus/week?weekId=${weekId}`, week, axios.defaults.headers.common)
      dispatch(setLoadingFalse())
      if(success){
        // toast.success(message)
        dispatch(setLoadingTrue())
        await getBatch()
        dispatch(setLoadingFalse())
      }
      else
        toast.error(message)
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  };
  return (
    <>
      <form onSubmit={handleClick}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>Edit week</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for this week
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Name</h6>
              <input
                type="text"
                required
                value={week.name}
                onChange={(e) => {
                  setWeek({
                    ...week,
                    name: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the title of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Description</h6>
              <textarea
                required
                value={week.description}
                onChange={(e) => {
                  setWeek({
                    ...week,
                    description: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default EditWeekModal;
