import {
    createSlice,
} from "@reduxjs/toolkit";


const initialState = {
    weeksOpened:[],
    refreshWeeks: false,
    batchId: null,
    dayId : null
};

const optionsSlice = createSlice({
    name: "options",
    initialState,
    reducers: {
        setWeeksOpened(state, action){
            if(state.weeksOpened.includes(action.payload))
                state.weeksOpened = state.weeksOpened.filter((id)=>{
                    if(id != action.payload)
                        return id
                })
            else
                state.weeksOpened.push(action.payload);
        },
        setWeeksTrue(state,action){
            if(!state.weeksOpened.includes(action.payload))
          {
            state.weeksOpened.push(action.payload);
          }
        },
        setRefreshWeeksTrue(state, action) {
            state.refreshWeeks = true
        },
        setRefreshWeeksFalse(state, action) {
            state.refreshWeeks = false
        },
        setBatchId(state,action){
            state.batchId = action.payload;
        },
        setDayId(state,action){
            state.dayId = action.payload;
        }
    }
  
});

export const { setWeeksOpened,setWeeksTrue, setRefreshWeeksTrue, setRefreshWeeksFalse , setBatchId, setDayId } = optionsSlice.actions;


export default optionsSlice.reducer;
