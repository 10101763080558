import TeacherCountCard from "../../components/TeacherCountCard/TeacherCountcard";
import { FaPaw } from "react-icons/fa";
import { MdBook } from "react-icons/md";
import { MdOutlineLiveTv } from "react-icons/md";
import TeacherClassCard from "../../components/TeacherClassCard/TeacherClassCard";
import Modal from "../../components/modal/modal";
import { useState, useEffect } from "react";
import axios from "axios";
import { serviceGet } from "../../utils/api";
import { Link, useNavigate, useParams } from "react-router-dom";
import { setHeader } from "../../utils/header";
import { useDispatch, useSelector } from "react-redux";
import { profileState } from "../../redux/features/auth/selectors";

import Confirmation from "../../components/confirmation/ConfirmationMeeting";
import { loadBatchId } from "../../redux/features/options/selectors";
import {
  setBatchId,
  setDayId,
  setWeeksTrue,
} from "../../redux/features/options/slice";
import GoogleConnect from "../../layout/GoogleConnect";
import Navbar from "../../layout/Navbar/Navbar";
import toast from "react-hot-toast";

const MentorDashboard = () => {
  const dayCode = {
    0: 0,
    1: 6,
    2: 5,
    3: 4,
    4: 3,
    5: 2,
    6: 1,
  };

  const [open, setopen] = useState(false);

  const [meetingNumber, setmeetingNumber] = useState("");
  const [name, setname] = useState("");
  const [date, setdate] = useState("");
  const [data, setData] = useState([]);
  const [batch, setBatch] = useState([]);
  const user = useSelector(profileState);
  const id = useSelector(loadBatchId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [sessionsThisWeek, setSessionsThisWeek] = useState([]);
  const start = new Date();
  const end = new Date();
  end.setDate(end.getDate() + dayCode[end.getDay()]);

  // useEffect(() => {
  //   if (!user?.profileComplete) {
  //     navigate("/me");
  //     toast("You need to complete your profile before proceeding");
  //   }
  // }, []);
  const getSessions = async () => {
    try {
      const token = localStorage.getItem("token");
      // axios.defaults.headers.common['auth'] = `bearer ${token}`;
      setHeader("auth", `bearer ${token}`);
      const st = new Date(start + start.getTimezoneOffset());
      const en = new Date(end + end.getTimezoneOffset());
      st.setUTCHours(0, 0, 0);
      en.setUTCHours(23, 59, 59);
      const res = await serviceGet(
        `mentor/mentor-api/v1/upcommingSessions?batchId=${id}&start=${st.toISOString()}&end=${en.toISOString()}`,
        axios.defaults.headers.common
      );
      const {
        success,
        data: { upcommingSessions },
        message,
      } = res;
      setSessionsThisWeek(upcommingSessions);
    } catch (error) {
      console.log(error.message);
    }
  };

  const getBatch = async () => {
    try {
      const token = localStorage.getItem("token");
      setHeader("auth", `bearer ${token}`);
      const res = await serviceGet(
        `mentor/mentor-api/v1/batch?batchId=${id}`,
        axios.defaults.headers.common
      );
      const {
        success,
        data: { batch },
        message,
      } = res;
      setBatch(batch);
      const sessions = [];
      batch?.syllabus?.map((syllabus) =>
        syllabus?.weeks?.map((week) =>
          week?.days?.map((day) =>
            day?.sessions?.map((session) =>
              new Date(session?.date) >= Date.now()
                ? sessions.push(session)
                : null
            )
          )
        )
      );
      setSessions(sessions);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (id != "undefined") {
      getBatch();
      getSessions();
      // getStudentAttendance();
    }
  }, [id]);

  return (
    <>
      {/* <GoogleConnect/> */}
      <Navbar />
      <Modal state={{ open }}>
        <Confirmation
          setOpen={setopen}
          meetingNumber={meetingNumber}
          name={name}
          date={date}
        />
      </Modal>

      <div className="bg-mainContainer py-10 px-2 md:p-10 ">
        <div>
          <h3 className="text-gray-600 text-2xl">
            Welcome Back, <strong>{user?.firstName}</strong>{" "}
          </h3>
          <h6 className="text-gray-500 text-lg">
            You are assigned Batch of <u>{batch?.name}</u>
          </h6>
        </div>
        <div className="  tablet:flex gap-10 my-10 mt-20 ">
          <div className="flex-col w-full md:w-4/5">
            <div className="w-full  md:flex gap-6  mb-2 justify-around">
              <div className="w-full mb-5 ">
                <TeacherCountCard
                  title="Total Students"
                  count={batch?.studentCount}
                  logo={<FaPaw />}
                  color="blue"
                />
              </div>
              <div className="w-full mb-5 ">
                <TeacherCountCard
                  title="Total Classes left This Week"
                  count={sessionsThisWeek?.length}
                  logo={<MdOutlineLiveTv />}
                  color="green"
                />
              </div>
            </div>

            <div className="flex gap-10 bg-white w-full rounded-lg p-7 mb-20 font-semibold mb-2 cursor-pointer">
              <div
                className={` text-xl rounded-lg p-5 text-blue-800 bg-mainContainer`}
              >
                <MdBook />
              </div>
              <div className="w-full text-gray-500 text-lg grid place-items-left my-auto">
                <Link to="/dashboard/attendance">View Attendance</Link>
              </div>
            </div>

            {sessions.length !== 0 ? (
              <div className="flex-col gap-20 bg-white w-full rounded-lg p-7">
                <h6 className="text-lg">Upcoming Classes</h6>
                {sessions.slice(0, 3).map((session) => {
                  return (
                    <div
                      key={session?._id}
                      onClick={() => {
                        dispatch(setBatchId(session?.batch));
                        dispatch(setDayId(session?.day));
                        dispatch(setWeeksTrue(session?.week));
                        navigate("/syllabus/day");
                      }}
                    >
                      <TeacherClassCard key={session?._id} session={session} />
                    </div>
                  );
                })}
                <Link to={`/schedule/`}>
                  <div className="flex cursor-pointer justify-end text-blue-800 mt-10">
                    See More ➝
                  </div>
                </Link>
              </div>
            ) : (
              <div className="flex-col gap-20 bg-white w-full rounded-lg p-7 font-semibold">
                No upcoming classes available
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MentorDashboard;