import axios from "axios";
import { useEffect, useState } from "react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTable, usePagination, useSortBy } from "react-table";
import { serviceGet } from "../../../utils/api";
import { setHeader } from "../../../utils/header";
import { loadBatchId } from "../../../redux/features/options/selectors";
import { FiChevronDown, FiChevronUp, FiFilter } from "react-icons/fi";
import Pill from "./Pill/Pill";
import { useDispatch } from "react-redux";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../../redux/features/loading/slice";

const Table = () => {
  const id = useSelector(loadBatchId);
  const [studentDetails, setStudentDetails] = useState([]);
  const [totalSession, setTotalSession] = useState();
  const [attendanceDetails, setAttendanceDetails] = useState();
  const [selectedPage, setSelectedPage] = useState(1);
  const dispatch = useDispatch();
  const getStudentAttendance = async () => {
    try {
      dispatch(setLoadingTrue());
      const token = localStorage.getItem("token");
      setHeader("auth", `bearer ${token}`);
      const res = await serviceGet(
        `mentor/mentor-api/v1/getStudentDetails?batchId=${id}`,
        axios.defaults.headers.common
      );
      if (res?.data) {
        setTotalSession(res?.data?.sessionDetails.length);
        setStudentDetails(res?.data?.studentDetails);
        setAttendanceDetails(res?.data?.attendanceDetails);
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  useEffect(() => {
    if (id != "undefined") {
      getStudentAttendance();
    }
  }, [id]);
  const attendanceCount = [];
  const attendanceData = useMemo(
    () => studentDetails,
    [studentDetails, totalSession, attendanceDetails]
  );
  studentDetails?.map((studentDetail) => {
    const id = studentDetail._id;
    let count = 0;
    attendanceDetails?.map((details) => {
      if (details.student == id) count++;
    });
    attendanceCount.push(count);
  });
  for (let i = 0; i < attendanceCount.length; i++) {
    studentDetails[i] = { ...studentDetails[i], count: attendanceCount[i] };
  }
  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "firstName",
        Cell: (props) => (
          <>
            <p className="item title">
              {props.row.original.firstName} {props.row.original.lastName}
            </p>
          </>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile",
        accessor: "mobile",
        Cell: (props) => (
          <>
            <p className="item title">
              {props.row.original.mobile ? (
                props.row.original.mobile
              ) : (
                <Pill text="Profile Incomplete" red />
              )}
            </p>
          </>
        ),
      },
      {
        Header: "Attendance",
        accessor: "count",
        Cell: (props) => (
          <>
            <Pill
              text={props.row.original.count}
              red={props.row.original.count < totalSession / 2 ? true : false}
            />
          </>
        ),
      },
      {
        Header: "Total Session",
        accessor: "totalSession",
        Cell: (props) => (
          <>
            <p className="item title">{totalSession}</p>
          </>
        ),
      },
    ],
    [studentDetails, totalSession, attendanceDetails]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state,
    page,
  } = useTable(
    {
      columns,
      data: attendanceData,
      initialState: {
        pageSize: 10,
        sortBy: [
          {
            id: "count",
            desc: true,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );
  return (
    <>
      <div className="grid place-items-center text-3xl font-semibold mt-8">
        Attendance
      </div>
      <div className="grid place-items-center">
        <table {...getTableProps()} className="table-auto mt-6 bg-white">
          <thead className="bg-slate-100 px-2">
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()} className="px-2">
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b-2 border-gray-200 p-2 text-left"
                  >
                    <div
                      className={`${
                        column.render("Header") == "Attendance" && "flex gap-2"
                      }`}
                    >
                      <div
                        className={`${
                          column.render("Header") == "Attendance" &&
                          !column.isSorted &&
                          "mt-2"
                        }`}
                      >
                        {column.render("Header")}
                      </div>
                      {column.render("Header") == "Attendance" && (
                        <div className="mt-1">
                          {(!column.isSortedDesc || !column.isSorted) && (
                            <FiChevronUp />
                          )}
                          {(column.isSortedDesc || !column.isSorted) && (
                            <FiChevronDown />
                          )}
                          {/* <FiFilter /> */}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} className="p-2">
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  className="text-left mt-2 hover:bg-gray-100"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="pr-4 pl-2 text-gray-700 border-b-2 border-gray-300 py-3 cursor-pointer"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <div
          href="#"
          class="relative inline-flex items-center px-2 py-2 rounded-l-md text-sm font-medium text-gray-500 mt-5"
        >
          <span class="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            onClick={() => {
              previousPage();
              setSelectedPage((page) => (page - 1 == 0 ? page : page - 1));
            }}
            disabled={!canPreviousPage}
          >
            <path
              fill-rule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clip-rule="evenodd"
            />
          </svg>
          {pageOptions.map((_, idx) => {
            return (
              <div
                className={`z-10 relative inline-flex cursor-pointer items-center px-4 py-2 border bg-white text-sm font-medium ${
                  idx + 1 == selectedPage
                    ? "bg-indigo-50 border-indigo-500 text-indigo-600"
                    : ""
                }`}
                onClick={() => {
                  gotoPage(idx);
                  setSelectedPage(idx + 1);
                }}
              >
                {idx + 1}
              </div>
            );
          })}
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
            onClick={() => {
              nextPage();
              setSelectedPage((page) =>
                page + 1 < pageOptions.length ? page + 1 : page
              );
            }}
            disabled={!canNextPage}
          >
            <path
              fill-rule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>
    </>
  );
};

export default Table;
