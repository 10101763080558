import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";

const ConfirmationMeeting = ({ meetingNumber, setOpen,name,date,platform="zoom",url="" }) => {
    const {id} = useParams();
  return (
    <>
      <div className="pt-6 px-6 bg-white w-full ">
        <div className="flex gap-5 items-center mb-4">
         
         
            <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
              <h5>Do you want to start this meeting ?</h5>
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
                size="1.4rem"
              />
            </div>
         
          
        </div>
        <hr />

        <div className="w-full flex gap-8 justify-center p-4 ">
          {platform==='zoom'? <Link
          state={{name,date, url}}
            to={`/meeting/${meetingNumber}`}
            className="bg-purple-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            Yes
          </Link>:
          <div onClick={()=>{setOpen(false)}}>
            <a  target="_blank" href={url} className="bg-purple-600 p-3 px-5 font-semibold text-white rounded-full ">Yes</a>
          </div>
          } 
          <button
             onClick={() => {
                setOpen(false);
              }}
            className="bg-red-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationMeeting;
