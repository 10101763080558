import React from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { profileState } from "../../redux/features/auth/selectors";
const Navbar = () => {
  const profile = useSelector(profileState);

  return (
    <>
      <div className="w-full bg-mainContainer flex justify-end p-4 -mt-8 sidebar:mt-0">
        <Link to={"/me"}>
          {profile?.image ? (
            <img src={profile?.image} alt="profile" className="rounded-full cursor-pointer mr-20 sidebar:mr-0 w-8 object-cover" />
          ) : (
            <div className="p-2 rounded-full bg-black cursor-pointer mr-20 sidebar:mr-0">
              <AiOutlineUser color="white" />
            </div>
          )}
        </Link>
      </div>
    </>
  );
};

export default Navbar;
