import React from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setBatchId, setDayId, setWeeksTrue } from '../../redux/features/options/slice'

import TeacherClassCard from '../TeacherClassCard/TeacherClassCard'

const TeacherScheduleClasses = ({ start, end, sessions }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // same as student side, not receving meeting number from backend here too
  return (
    <>
       {
    start.toDateString()===end.toDateString() ? 
    <h4 className="text-2xl mb-5">{start.toDateString()}</h4>
    :
    <h4 className="text-2xl mb-5">{`${start.toDateString()} to ${end.toDateString()}`}</h4>
    
   }
      {sessions?.map((session) => {
        const dt = new Date(
          new Date(session?.date).toLocaleString("en-US", { timeZone: "UTC" })
        );
        const dt_e = new Date(new Date(session?.date).toLocaleString('en-US', { timeZone: 'UTC' }));
  dt_e.setHours(dt.getHours()+2)

        return (
          <div
            key={session?._id}
            className="cursor-pointer"
            onClick={() => {
                if(new Date() <= dt_e)
                {
                    dispatch(setBatchId(session?.batch))
                  dispatch(setDayId(session?.day))
                 dispatch(setWeeksTrue(session?.week))
                  navigate("/syllabus/day")
                }else toast.error("meeting expired")
            }}
          >
            <TeacherClassCard key={session?._id} session={session} />
          </div>
        );
      })}
      {sessions.length === 0 ? "No scheduled sessions" : ""}
    </>
  );
};

export default TeacherScheduleClasses;
