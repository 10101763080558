import axios from "axios";
import { useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import { servicePut } from "../../utils/api";

const SubmissionCard = ({ resource, student, submission }) => {
  const dispatch = useDispatch();
  const [score, setscore] = useState(submission?.score);
  const { id } = useParams();
  const gradeSubmission = async (e) => {
    dispatch(setLoadingTrue());
    try {
       setscore(e.target.value);
      
      const { message } = await servicePut(
        `mentor/mentor-api/v1/submission?id=${submission?._id}&score=${score}`,{},
        axios.defaults.headers.common
      );
      // toast.success(message);
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  return (
    <div className="bg-white flex-col gap-5 rounded-lg flex p-2 justify-around my-4 flex-wrap mx-10 ">
      <div className="flex justify-between items-center flex-wrap ">
        <div className="md:w-8/12 flex flex-col gap-2">
          <div className="">
            <strong>Student:- </strong>
            {student}
          </div>
          <div className="break-words w-full text-indigo-600">
            {resource ? (
              <a href={resource}>
                <strong >Uploaded file </strong>
              </a>
            ) : (
              <h1>No file Uploaded</h1>
            )}
          </div>
        </div>
        <div>
          {
            resource &&
            <div className="relative w-full lg:max-w-sm flex gap-4 items-center">
              <p>Marks: </p>
              <select
                value={score}
                onChange={gradeSubmission}
                className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
              >
                <option disabled value="marks">
                  Marks
                </option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
export default SubmissionCard;
