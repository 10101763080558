import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { serviceGet, servicePut } from "../../utils/api";
import GoogleConnect from "../../layout/GoogleConnect";
import { profileState } from "../../redux/features/auth/selectors";
import { loadUser } from "../../redux/features/auth/slice";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(profileState);
  const [profile, setprofile] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    image: "",
    email:"",
    address: {
      city: "",
      pincode: "",
      state: "",
    },
  });
  const handleImageUpload = async (e) => {
    // console.log("image uploaded ?");
    e.preventDefault();
    dispatch(setLoadingTrue());

    try {
      const file = e.target.files[0];
      if (e.target.files[0].type.split("/")[0] !== "image") {
        toast.error("Only Images allowed");
        return;
      }

      const type = "." + e.target.files[0].type.split("/")[1];
      const { url } = await serviceGet(
        `mentor/mentor-api/v1/me/url?type=${type}`
      );

      await fetch(url, {
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        body: file,
      });

      const imageUrl = url.split("?")[0];

      const {
        success,
        data: { mentor },
        message,
      } = await servicePut(
        "mentor/mentor-api/v1/me/update-profile-picture",
        {
          url: imageUrl,
        },
        axios.defaults.headers.common
      );

      if (success) {
        // toast.success(message);
        setprofile({
          ...profile,
          image: mentor?.image,
        });
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoadingTrue());
    try {
      const {
        success,
        data: { mentor },
        message,
      } = await servicePut(
        "mentor/mentor-api/v1/me",
        profile,
        axios.defaults.headers.common
      );

      if (success) {
        // toast.success(message);
        setprofile({
          firstName: mentor?.firstName,
          lastName: mentor?.lastName,
          mobile: mentor?.mobile,
          address: mentor?.address,
          ...profile,
        });
        dispatch(loadUser());
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  const getProfile = async () => {
    dispatch(setLoadingTrue());
    try {
      const {
        success,
        data: { mentor },
        message,
      } = await serviceGet("mentor/mentor-api/v1/me");
      if (success) {
        // toast.success(message);
        setprofile({
          firstName: mentor.firstName,
          lastName: mentor.lastName,
          mobile: mentor.mobile,
          email: mentor?.email,
          address: mentor?.address,
          image: mentor?.image,
        });
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <div className=" min-h-screen w-full  flex flex-col sidebar:flex-row justify-around items-center ">
      {/* <div className="mt-10 md:p-4 bg-red-500  sm:mt-0 "> */}

      <div className="px-4 sm:px-0 sidebar:w-1/4">
        {/* <h3 className="text-lg font-medium leading-6 text-gray-900 mb-6">Personal Information</h3> */}
        <label htmlFor="desktop-upload">
          <img
            title="Change Profile"
            src={
              profile?.image
                ? profile?.image
                : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
            }
            className="rounded-none m-auto h-96 lg:rounded-lg shadow-2xl  cursor-pointer hover:brightness-75"
          />
        </label>
        <input
          accept="image/*"
          onChange={handleImageUpload}
          id="desktop-upload"
          className="hidden"
          type="file"
        />
      </div>

      <div className="p-2 mt-5 md:mt-0 w-full sidebar:w-1/2">
        <form onSubmit={handleSubmit}>
          <div className="shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <input
                    value={profile?.firstName}
                    onChange={(e) =>
                      setprofile({ ...profile, firstName: e.target.value })
                    }
                    type="text"
                    name="first-name"
                    required
                    id="first-name"
                    autoComplete="given-name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <input
                    value={profile?.lastName}
                    onChange={(e) =>
                      setprofile({ ...profile, lastName: e.target.value })
                    }
                    type="text"
                    required
                    name="last-name"
                    id="last-name"
                    autoComplete="family-name"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <label
                    htmlFor="Mobile-Number"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Mobile Number
                  </label>
                  <input
                    value={profile?.mobile}
                    onChange={(e) =>
                      setprofile({ ...profile, mobile: e.target.value })
                    }
                    type="tel"
                    required
                    name="Mobile-Number"
                    id="Mobile-Number"
                    autoComplete="mobile"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <input
                    value={profile?.address?.city}
                    required
                    onChange={(e) =>
                      setprofile({
                        ...profile,
                        address: { ...profile?.address, city: e.target.value },
                      })
                    }
                    type="text"
                    name="city"
                    id="city"
                    autoComplete="address-level2"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="region"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State / Province
                  </label>
                  <input
                    value={profile?.address?.state}
                    onChange={(e) =>
                      setprofile({
                        ...profile,
                        address: { ...profile?.address, state: e.target.value },
                      })
                    }
                    required
                    type="text"
                    name="region"
                    id="region"
                    autoComplete="address-level1"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>

                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    ZIP / Postal code
                  </label>
                  <input
                    value={profile?.address?.pincode}
                    onChange={(e) =>
                      setprofile({
                        ...profile,
                        address: {
                          ...profile?.address,
                          pincode: e.target.value,
                        },
                      })
                    }
                    type="text"
                    required
                    name="postal-code"
                    id="postal-code"
                    autoComplete="postal-code"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                <div className="col-span-6">
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email
                  </label>
                  <input
                    value={profile?.email}
                    disabled                    
                    type="text"
                    required
                    name="email"
                    id="email"
                    className="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full py-2 shadow-sm sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
            {!user?.profileComplete ? (
              <div className="px-4 py-3 bg-gray-50 flex flex-col gap-4 tablet:flex-row items-center  justify-between sm:px-6">
                <div className="w-full  px-0 tablet:w-3/5">
                  Note : You need to complete the profile before moving ahead
                </div>

                <div className="flex gap-2 items-center">
                  <div>
                    <GoogleConnect />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2.5 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="px-4 py-3 bg-gray-50 flex  gap-4 items-center  justify-between sm:px-6">
                <div>
                  <GoogleConnect />
                </div>
                <div>
                  <button
                    type="submit"
                    className="inline-flex justify-center py-2.5 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
        {/* </div> */}

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
