import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { authState } from "../../redux/features/auth/selectors";
import { logout } from "../../redux/features/auth/slice";

import { MdDashboard } from "react-icons/md";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { BiMessageDots } from "react-icons/bi";
import { FaShapes, FaBook } from "react-icons/fa";
import Logo from "../../assets/images/Logo.svg";
import Dropdown from "./Dropdown";
import Modal from "../modal/modal";
import ConfirmationLogout from "../confirmation/ConfirmationLogout";
import { loadBatchId } from "../../redux/features/options/selectors";
import { setDayId } from "../../redux/features/options/slice";

const mentorNavbarConfig = [
  {
    title: "Dashboard",
    url: "/dashboard",
    icon: <MdDashboard />,
  },
  {
    title: "My Schedule",
    url: "/schedule",
    icon: <BsFillCalendar2Fill />,
  },
  {
    title: "Syllabus",
    url: "/syllabus",
    icon: <FaBook />,
  },
  {
    title: "Assignments",
    url: "/assignments",
    icon: <FaShapes />,
  },
  // {
  //   title: "Messages",
  //   url: "/messages",
  //   icon: <BiMessageDots />,
  // },
];

const MobileSidebar = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [open, setopen] = useState(false);
  const location = useLocation();
  const id = useSelector(loadBatchId);
  const url = location.pathname.split("/")[1];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(authState);

  useEffect(() => {
    if (!isAuthenticated) navigate("/auth");
  }, [isAuthenticated, navigate]);

  return (
    <>
      <Modal state={{ open }}>
        <ConfirmationLogout setOpen={setopen} />
      </Modal>
      {showSidebar ? (
        <button
          className="flex text-4xl text-white items-center cursor-pointer fixed right-6 top-6 z-50"
          onClick={() => setShowSidebar(!showSidebar)}
        >
          x
        </button>
      ) : (
        <svg
          onClick={() => setShowSidebar(!showSidebar)}
          className="absolute  z-30 flex items-center cursor-pointer right-6 top-6"
          fill="#2563EB"
          viewBox="0 0 100 80"
          width="30"
          height="30"
        >
          <rect width="100" height="10"></rect>
          <rect y="30" width="100" height="10"></rect>
          <rect y="60" width="100" height="10"></rect>
        </svg>
      )}

      <div
        className={`top-0 right-0 w-[65vw] bg-sidebar px-4 p-10 text-white fixed h-full z-40  ease-in-out duration-300 ${
          showSidebar ? "translate-x-0 " : "translate-x-full"
        }`}
      >
        <div className="h-full  text-white  ">
          <div className="w-full  py-5  h-full  text-center ">
            <div className="flex justify-center align-center my-5 ">
              <img src={Logo} alt="logo" />
            </div>
            <div className="my-2  w-full">
              <Dropdown />
            </div>
            <div
             
              className="  w-full flex flex-col justify-between   "
            >
              <div  onClick={() => {
                dispatch(setDayId(null));
              }}>
                {mentorNavbarConfig.map((page) => {
                  return (
                    <div
                      key={page.title}
                      className={` w-full  rounded-md ${
                        "/" + url === page.url && "bg-selectedLink"
                      } `}
                    >
                      <Link
                        to={page.url}
                        className={`flex w-full  text-center gap-5 p-5 pl-4 items-center `}
                      >
                        <p className="text-xl text-center w-full">
                          {page.title}
                        </p>
                      </Link>
                    </div>
                  );
                })}
              </div>
              <div className=" w-full py-4 rounded-md ">
                <button
                  className="flex w-full gap-5 items-center  "
                  onClick={() => {
                    setopen(true);
                  }}
                >
                  <p className="text-xl text-center w-full">Logout</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileSidebar;
