    import React, { useState, useEffect } from 'react'

    import { setHeader } from '../../utils/header';
    import { serviceGet } from '../../utils/api';
    import axios from 'axios';
    import { useNavigate, useParams } from 'react-router-dom';

    import { DateRangePicker } from 'react-date-range';
    import 'react-date-range/dist/styles.css';
    import 'react-date-range/dist/theme/default.css';
    import TeacherScheduleClasses from '../../components/TeacherScheduleClasses/TeacherScheduleClasses';
import { useDispatch, useSelector } from 'react-redux';
import { loadBatchId } from '../../redux/features/options/selectors';
import { loadEnd, loadStart } from '../../redux/features/date/selector';
import { setEndDate, setStartDate } from '../../redux/features/date/slice';
import Navbar from '../../layout/Navbar/Navbar';
import { profileState } from '../../redux/features/auth/selectors';

    const MentorSchedule = () => {
        // const [start, onChangeStart] = useState(new Date());
        // const [end, onChangeEnd] = useState(new Date());
        const start = useSelector(loadStart);
        const end = useSelector(loadEnd);
        const profile = useSelector(profileState);
        const navigate = useNavigate();
        const dispatch = useDispatch();
        const [sessions, setSessions] = useState([]);
        const id=useSelector(loadBatchId);

        const selectionRange = {
            startDate: start,
            endDate: end,
            key: 'selection',
        }

        const handleChange = (ranges) => {
            const { selection:{startDate, endDate} } = ranges;
            dispatch(setStartDate(startDate));
            dispatch(setEndDate(endDate));
            // onChangeStart(startDate)
            // onChangeEnd(endDate)
        }

        const getSessions = async () => {
            try {
                const token = localStorage.getItem('token');
                // axios.defaults.headers.common['auth'] = `bearer ${token}`;
                setHeader('auth', `bearer ${token}`);
                const st = new Date(start+start.getTimezoneOffset());
                const en = new Date(end+end.getTimezoneOffset());
                st.setUTCHours(0,0,0);
                en.setUTCHours(23,59,59);
                const res = await serviceGet(`mentor/mentor-api/v1/upcommingSessions?batchId=${id}&start=${st.toISOString()}&end=${en.toISOString()}`, axios.defaults.headers.common);
                const {success, data:{upcommingSessions}, message} = res;
                setSessions(upcommingSessions)
            } catch (error) {
                console.log(error.message);
            }
        }

        useEffect(()=>{
            if(id != null)
                getSessions();
        }, [id, start, end])
    
        // useEffect(() => {
        //     if (!profile?.profileComplete) {
        //         navigate("/me");
        //         toast("You need to complete your profile before proceeding");
        //       }
        // }, [])
        return (
            <>
            <Navbar/>
            
                <div className='bg-mainContainer  px-2 md:px-10 ' >
                        <div className='flex justify-between items-center'>
                            <div>
                                <h3 className='text-gray-600 text-2xl'> <strong>My Schedule</strong> </h3>
                                <h6 className='text-gray-500 text-lg'>Create and Manage Your Schedule</h6>
                            </div>
                        </div>
                        
                            <hr/>
                    
                        <hr/>
                   
                    <div className='flex flex-col-reverse tablet:flex-row   justify-between mt-5'>
                        <div className='w-full tablet:w-2/5 flex-col ' >
                            <TeacherScheduleClasses start={start} end={end} sessions={sessions}/>
                        </div>
                        <div className='w-full tablet:w-7/12 overflow-x-auto'>
                            <DateRangePicker
                        className='tablet:float-right w-full'

                                ranges={[selectionRange]}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
            </div>
            </>
         
        )
    }

export default MentorSchedule;
