import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../../assets/lottie/loader.json'
const Loader = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      };
     
      
  return (
    <div className='w-full h-full  md:p-4 flex justify-center items-center'>

        {/* <div className='text-4xl text-purple-700 text-center font-semibold py-4'>
                Coming Soon !
        </div> */}

        <div className='w-full h-max' >
        <Lottie options={defaultOptions}
            width={'30%'}
            height={'40vh'}
            isClickToPauseDisabled={true}
             
            />
        </div>
    </div>
  )
}

export default Loader;