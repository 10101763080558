import React from "react";

export default function Modal(props) {
  const {open} = props.state;
  return (
    <>
     
      {open ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative bg-white w-5/6 tablet:w-1/3 my-6 mx-auto max-w-3xl">
              {/*content*/}
                 {props.children}
             
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
