import React from "react";
import { useSelector } from "react-redux";

import { Outlet } from "react-router-dom";
import Profile from "../../pages/Profile/Profile";
import { profileState } from "../../redux/features/auth/selectors";

import MobileSidebar from "../Sidebar/MobileSidebar";
import Sidebar from "../Sidebar/Sidebar";

const SidebarWrapper = () => {
  const profile = useSelector(profileState);

  return (
    <>
      <div className="w-full flex min-h-screen h-full bg-mainContainer ">
        <div className="w-1/4 min-h-screen bg-sidebar  hidden  sidebar:block    ">
          <div className="w-1/5 fixed top-0">
            <Sidebar />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center sidebar:hidden min-h-screen py-2">
          <MobileSidebar />
        </div>

        <div className="w-full  mt-10 sidebar:mt-0 bg-mainContainer ">
          {profile?.profileComplete ? <Outlet /> : <Profile />}
        </div>
      </div>
    </>
  );
};

export default SidebarWrapper;
