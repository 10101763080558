import React,{useState, useEffect} from 'react'
import Modal from '../modal/modal'
import WeekDropdown from './WeekDropdown'

import { setHeader } from '../../utils/header';
import { serviceGet } from '../../utils/api';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingFalse, setLoadingTrue } from '../../redux/features/loading/slice';
import toast from 'react-hot-toast';
import AddWeekModal from '../addWeek/AddWeek';
import { loadBatchId, refreshWeeks } from '../../redux/features/options/selectors';
import { setRefreshWeeksFalse } from '../../redux/features/options/slice';

const SyllabusSelector = () => {
  const [open, setOpen] = useState(false);
  const id = useSelector(loadBatchId);
  const {classId} = useParams();
  const [weeks, setWeeks] = useState([]);
  const [batch, setBatch] = useState(null);
  const dispatch = useDispatch();
  const rw = useSelector(refreshWeeks);

  const getBatch = async () => {
   
      try {
          const token = localStorage.getItem('token');
          // axios.defaults.headers.common['auth'] = `bearer ${token}`;
          setHeader('auth', `bearer ${token}`);
          dispatch(setLoadingTrue());
          const res = await serviceGet(`mentor/mentor-api/v1/batch?batchId=${id}`, axios.defaults.headers.common);
          dispatch(setLoadingFalse());
          
          const {success, data:{batch}, message} = res;
          if(batch.syllabus[0]!=undefined){
            
            setWeeks(batch.syllabus[0].weeks);
          }
          else{
            setWeeks([]);
          }
          dispatch(setRefreshWeeksFalse());
          setBatch(batch);
      } catch (error) {
        dispatch(setLoadingFalse());

          console.log(error.message);
      }
  }


  useEffect(()=>{
    if(id != "undefined")
        getBatch();
  }, [id])

  useEffect(()=>{
    if(rw)
      getBatch();
  }, [rw])

  return (
    <>
      <Modal state={{ open }}>
        <AddWeekModal  setOpen={setOpen} batch={batch} getBatch={getBatch}/>
      </Modal>
      <div className='flex items-center justify-between p-2 border-b-2 border-gray-300 '>
            <div className='font-semibold '>
                Manage Weeks
            </div>
            <div className='flex gap-5'>
              <button onClick={() => { setOpen(true) }} className='border-2 border-gray-400 rounded-md py-1 px-2'>
                  Add +
              </button>
            </div>
      </div>  
      <div className="pt-4 z-0">
        { weeks?.length!==0 ?
          weeks?.map((week)=>{
            
            return(<WeekDropdown key={week?._id} week={week} batch={batch} getBatch={getBatch}/>)
          })
          :
          <div className='text-center font-semibold'>
            No weeks Found !
          </div>
        }
      </div>
    </>
  )
}

export default SyllabusSelector;