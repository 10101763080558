import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import DeleteAssignment from "../../components/assignmentCard/DeleteAssignmentModal";
import EditAssignmentModal from "../../components/assignmentCard/EditAssignmentModal";
import Modal from "../../components/modal/modal";
import SubmissionCard from "../../components/submissionCard/SubmissionCard";
import { serviceGet, servicePost, servicePut } from "../../utils/api";
import Navbar from "../../layout/Navbar/Navbar";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import { loadBatchId } from "../../redux/features/options/selectors";

const Assignment = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [assignment, setassignment] = useState({});
  const [submissions, setsubmissions] = useState([]);
  const [open, setopen] = useState(false);
  const [modalType, setModalType] = useState("");
  const batchId = useSelector(loadBatchId);
  const getAssignment = async () => {
    dispatch(setLoadingTrue());
    try {
      const { data } = await serviceGet(
        `mentor/mentor-api/v1/single-assignment?id=${id}`,
        axios.defaults.headers.common
      );
      setassignment(data?.assignments);
      // toast.success("Assignments Fetched");
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  
  const getSubmission = async () => {
    dispatch(setLoadingTrue());
    try {
      const { data } = await serviceGet(
        `mentor/mentor-api/v1/submission?id=${id}&batchId=${batchId}`,
        axios.defaults.headers.common
      );
      setsubmissions(data?.submissions);
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  const oneResourcePromise = async(file)=>{
    try {
      const fileSize = file.size;
      if (fileSize > 1.5e7) {
        toast.error("Maximum file size is 15MB");
        return;
      }
      const type = file.type.split("/")[1];
      if (type === "pdf" || type === "zip") {
        const {data : {uploadURL} }= await serviceGet(
          `mentor/mentor-api/v1/assignment/resourceUploadUrl?extension=${"." + type}&batchId=${batchId}`
        );
        // console.log(uploadURL);
        await fetch(uploadURL, {
          method: "PUT",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: file,
        });
        const url = uploadURL.split("?")[0];
        if (url) {
          const { data } = await servicePost(
            `mentor/mentor-api/v1/assignment/resource?id=${id}`,
            {url},
            axios.defaults.headers.common
          );
        }
        
      } else {
       
        toast.error("Only pdf and zip files are allowed");
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      dispatch(setLoadingFalse());
    }
  }
  const handleResourceUpload = async(e)=>{
    dispatch(setLoadingTrue());
    try {
      let file = e.target.files;
        //convert files list into array
        file = Array.from(file);
        Promise.all(file.map(async (element, i) => {
          return new Promise((resolve, reject) => {
              resolve(oneResourcePromise(element))
          });
      })).then(res=> {
        // toast.success("Resource Added Succesfully");
        getAssignment();
        dispatch(setLoadingFalse()); 
      }).catch(e=>{
        console.log(e);
        // toast.error(e);
      });
      e.target.value = null;
    } catch (error) {
      toast.error(error.response.data.message);
      dispatch(setLoadingFalse());
    }
   finally{
      e.target.value= null;
   }
  }
  useEffect(() => {
    getAssignment();
    getSubmission();
  }, []);
  const d = new Date(assignment?.dueDate);
  return (
    <>
      <Modal state={{ open }}>
        {modalType === "edit" ? (
          <EditAssignmentModal
            getAssignment={getAssignment}
            assignment={assignment}
            setopen={setopen}
          />
        ) : (
          <DeleteAssignment setOpen={setopen} />
        )}
      </Modal>
      <Navbar />

      <div className="flex items-center justify-between mt-2 pt-2 pb-2 border-t-2 border-b-2 border-gray-300 p-4 ">
        <div className="flex flex-col gap-6">
          <div className="font-semibold text-2xl">{assignment?.name}</div>
          <div className="text-gray-500 flex gap-2 items-center">
            {assignment?.description}
          </div>
          <div className="font-semibold text-md">
            Due Date: {d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear()}
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <button
            className="bg-btn rounded-md text-white p-2"
            onClick={() => {
              setModalType("edit");
              setopen(true);
            }}
          >
            Edit
          </button>
          <button
            className="bg-red-600 rounded-md text-white p-2"
            onClick={() => {
              setModalType("delete");
              setopen(true);
            }}
          >
            Delete
          </button>
        </div>
      </div>
      <div className="p-4 border-b-2 border-gray-300">
        <div className="flex justify-between items-center">
          <div className="font-semibold   ">Resources</div>
          <div>

          <input
                type="file"
                multiple
                
                onChange={handleResourceUpload}
                className="w-full rounded-lg  focus:outline focus:outline-2 focus:outline-blue-500 file:bg-purple-600 file:rounded-lg file:px-2 file:cursor-pointer file:py-1 file:text-white file:border-none"
                placeholder="Enter the URL of the Resource"
              />
          </div>
        </div>
        <div className="max-h-56 my-2 overflow-y-auto">
          {assignment?.resources?.map((resource, idx) => {
            return (
              <div key={idx}>
               
                <div className="bg-white flex-col gap-5 rounded-lg flex px-4 py-8 justify-around my-4 flex-wrap mx-10 break-all font-bold ">
                    <a href={resource}>Resource-{idx+1}</a></div>
              </div>
            );
          })}
        </div>
        {assignment?.resources?.length === 0 && "No resources available"}
      </div>
      <div className=" border-b-2 border-gray-300 ">
        <div className="font-semibold  p-4 ">Submissions</div>
        <div className="max-h-[500px] overflow-auto  md:w-[97%] m-auto">
        {submissions?.map((submission, idx) => {
          return (
            <div key={idx}>
              <SubmissionCard
                  submission ={submission}
                  student={(submission?.student?.firstName  ? submission?.student?.firstName : "") + " " + submission?.student?.lastName}
                  resource={submission?.resource}
                />
            </div>
          );
        })}
        </div>
        
        {submissions?.length === 0 && "No submissions available"}
      </div>
    </>
  );
};

export default Assignment;
