import { configureStore } from "@reduxjs/toolkit";
// import productsReducer from "../features/products/productsSlice";
import authReducer from "../features/auth/slice";
import loadingReducer from "../features/loading/slice";
import optionsReducer from "../features/options/slice";
import dateReducer from "../features/date/slice";
export const store = configureStore({
  reducer: {
    auth: authReducer,
    loading: loadingReducer,
    options: optionsReducer,
    date: dateReducer
  },
});
