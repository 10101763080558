import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import { AiOutlinePlus } from "react-icons/ai";
import { FiRadio } from "react-icons/fi";
import AboutProgram from "../../components/aboutProgram/AboutProgram";
import AddSessionModal from "../../components/addSession/AddSession";
import EditDayModal from "../../components/editDay/EditDay";
import Modal from "../../components/modal/modal";
import ModuleResource from "../../components/ModuleResource/ModuleResource";
import { useEffect } from "react";
import { serviceGet } from "../../utils/api";
import axios from "axios";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { loadBatchId, loadDayId } from "../../redux/features/options/selectors";
import AddVideoModal from "../../components/addSession/Addvideo";
import AddAssetModal from "../../components/addResource/AddResourceModal";
import ResourceCard from "../../components/ResourceCard/ResourceCard";

const ProgramDetail = () => {
  const batchId = useSelector(loadBatchId);
  const dayId = useSelector(loadDayId);
 
  const [open, setopen] = useState(false);
  const [modalType, setModalType] = useState("addClass");
  const [resources, setResources] = useState([]);
  const [day, setDay] = useState(null);
  const [sessions, setSessions] = useState([]);
  const [rerender, setRerender] = useState(false);
  const navigate = useNavigate();


  const getDay = async () => {
    try {
      const { data, success, message } = await serviceGet(
        `mentor/mentor-api/v1/syllabus/day?dayId=${dayId}`,
        axios.defaults.headers.common
      );
      if (success) {
        const { day } = data;
        setDay(day);
        setSessions(day?.sessions);
        // toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getResources = async () => {
    try {
      const { data } = await serviceGet(
        `mentor/mentor-api/v1/getresources?day=${dayId}&batch=${batchId}`,
        axios.defaults.headers.common
      );
      setResources(data);
      
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  useEffect(() => {
    if (dayId == null) {
      navigate("/syllabus");
      toast("please select a day");
    } else {
      getDay();
      getResources();
    }
  }, [dayId, rerender]);
  return (
    <>
      <Modal state={{ open }}>
        {modalType === "addClass" ? (
          <AddSessionModal
            batch={batchId}
            day={dayId}
            setopen={setopen}
            getSessions={getDay}
          />
        ) : modalType === "addVideo" ? (
          <AddVideoModal
            batch={batchId}
            day={dayId}
            setopen={setopen}
            getSessions={getDay}
            setRerender={setRerender}
          />
        ) : modalType === "addResource" ? (
          <AddAssetModal
            batch={batchId}
            day={dayId}
            setopen={setopen}
            getSessions={getDay}
            setRerender={setRerender}
          />
        ) : (
          <EditDayModal
            setopen={setopen}
            prev={day}
            dayId={dayId}
            getDay={getDay}
          />
        )}
      </Modal>

      <div className="flex items-center justify-between mt-2 pt-2 pb-2 border-t-2 border-b-2 border-gray-300 ">
        <div className="flex gap-6">
          <div className="font-semibold ">{day?.name}</div>
          <div className="text-gray-500 flex gap-2 items-center">
            <div className="flex gap-2 items-center">
              <FiRadio size="1.1rem" />
              <p>{day?.sessions.length}</p>
            </div>
           
          </div>
        </div>
        <div className="flex gap-6 items-center">
          <button
            className="bg-btn rounded-md text-white p-2"
            onClick={() => {
              setModalType("edit");
              setopen(true);
            }}
          >
            Edit
          </button>
        </div>
      </div>
      <AboutProgram branchName={day?.branchName} description={day?.description} />
      <div className="flex mt-2 justify-between items-center">
        <div className="font-semibold">Classes</div>
        <div>
          {/* <button className='border-2 border-gray-400 rounded-md p-1' onClick={() => { setModalType('addClass'); setopen(true) }}>
                <AiOutlinePlus/>
                </button> */}

          <div class="relative group ">
            <button className="border-2 border-gray-400 rounded-md p-1">
              <AiOutlinePlus />
            </button>
            <div class="absolute right-2 top-5 w-40  z-10 hidden bg-white group-hover:block">
              <div class="px-2 w-full pt-2 pb-4  bg-white shadow-lg mr-96">
                <button
                  onClick={() => {
                    setModalType("addClass");
                    setopen(true);
                  }}
                  class=" w-full text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-200 rounded-md"
                >
                  Add Class
                </button>
                <button
                  class=" w-full text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-200 rounded-md"
                  onClick={() => {
                    setModalType("addResource");
                    setopen(true);
                  }}
                >
                  Add Resource
                </button>
                <button
                  onClick={() => {
                    setModalType("addVideo");
                    setopen(true);
                  }}
                  class=" w-full text-gray-700 block px-4 py-2 text-sm cursor-pointer hover:bg-gray-200 rounded-md"
                >
                  Add Video
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-10 pt-5 px-0 md:px-10 md:pb-5">
        {sessions.map(({ topic, description, meeting, date, _id }) => {
          return (
            <ModuleResource
              topic={topic}
              meeting={meeting[0]}
              date={date}
              description={description}
              id={_id}
              getSessions={getDay}
            />
          );
        })}
        <div className="font-semibold md:-ml-10">Resources</div>
        {resources.map((resource, idx) => {
          return (
            <div key={idx}>
              <ResourceCard
                title={resource.title}
                description={resource.description}
                url={resource.destinationUrl}
                id = {resource._id}
                getResources = {getResources}
              />
            </div>
          );
        })}
        {resources?.length === 0 ? "No resources available" : ""}
      </div>
    </>
  );
};

export default ProgramDetail;
