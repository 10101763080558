import React, { useState } from "react";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { setLoadingTrue, setLoadingFalse } from "../../redux/features/loading/slice";
import { servicePut } from "../../utils/api";
import axios from "axios";
import toast from "react-hot-toast";

const EditSessionModal = ({ topic, agenda, startTime, date, setOpen, id, getSessions,platform }) => {
  console.log(platform)
  const [session, setSession] = useState({
    topic:topic,
    agenda:agenda,
    startTime:startTime,
    date:date
  });
  const dispatch = useDispatch();

  const handleSubmit = async(e) => {
    e.stopPropagation();
    e.preventDefault();
    setOpen(false);
    const date = `${session.date}T${session.startTime}:00`
    session.startTime = date;
    const values = {
      meetingOptions: session,
      platform
    }
    
    try {
      dispatch(setLoadingTrue())
      const {success, message, data} = await servicePut(`mentor/mentor-api/v1/session?sessionId=${id}`, values, axios.defaults.headers.common)
      dispatch(setLoadingFalse())
      if(success){
        // toast.success(message)
        dispatch(setLoadingTrue())
        await getSessions();
        dispatch(setLoadingFalse())
      }
      else
        toast.error(message)
    } catch (error) {
      toast.error(error.message)
      console.log(error)
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit} onClick={
        (e)=>{
          e.stopPropagation();
          e.preventDefault();
        }
      }>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>Edit session</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for this session
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Topic</h6>
              <input
                type="text"
                required
                value={session.topic}
                onChange={(e) => {
                  setSession({
                    ...session,
                    topic: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the topic of the session"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Agenda</h6>
              <textarea
                required
                value={session.agenda}
                onChange={(e) => {
                  setSession({
                    ...session,
                    agenda: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the agenda of the session"
              />
            </div>
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Date</h6>
              <input
                required
                value={session.date}
                onChange={(e) => {
                  setSession({
                    ...session,
                    date: e.target.value,
                  });
                }}
                type="date"
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Start Time</h6>
              <input
                value={session.startTime}
                onChange={(e) => {
                  setSession({
                    ...session,
                    startTime: e.target.value,
                  });
                }}
                required
                type="time"
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
            onClick={handleSubmit}
          >
            Update
          </button>
        </div>
      </form>
    </>
  );
};

export default EditSessionModal;
