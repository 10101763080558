import React, { useState } from "react";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { servicePost } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { profileState } from "../../redux/features/auth/selectors";
import axios from "axios";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import toast from "react-hot-toast";
import { getTodaysDate } from "../../utils/getTodaysDate";

const AddSessionModal = ({ setopen, day, batch, getSessions }) => {
  const [liveClassDetail, setliveClassDetail] = useState({
    title: null,
    description: null,
    date: null,
    startTime: null,
    doubtClass: false,
  });


  const [platform, setPlatform] = useState("zoom");

  const user = useSelector(profileState);
  const dispatch = useDispatch();

  const handleClick = async (e) => {
    e.preventDefault();
    setopen(false);

    const date = `${liveClassDetail.date}T${liveClassDetail.startTime}:00`;

    try {
      dispatch(setLoadingTrue());
      const { success, message, data } = await servicePost(
        "mentor/mentor-api/v1/session",
        {
          options: {
            topic: liveClassDetail.title,
            startTime: date,
            agenda: liveClassDetail.description,
          },
          user,
          day,
          batch,
          platform,
        },
        axios.defaults.headers.common
      );
      dispatch(setLoadingFalse());
      if (success) {
        // toast.success(message);
        dispatch(setLoadingTrue());
        await getSessions();
        dispatch(setLoadingFalse());
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <form onSubmit={handleClick}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>New Live Lecture</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setopen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide your details for the live lecture
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Title</h6>
              <input
                type="text"
                required
                value={liveClassDetail.title}
                onChange={(e) => {
                  setliveClassDetail({
                    ...liveClassDetail,
                    title: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the title of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Description</h6>
              <textarea
                required
                value={liveClassDetail.description}
                onChange={(e) => {
                  setliveClassDetail({
                    ...liveClassDetail,
                    description: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>

            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Date</h6>
              <input
                required
                value={liveClassDetail.date}
                onChange={(e) => {
                  setliveClassDetail({
                    ...liveClassDetail,
                    date: e.target.value,
                  });
                }}
                min={getTodaysDate()}
                type="date"
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
            <div className="flex gap-2 items-center mb-4">
              <div className="w-1/2">
                <h6 className="text-lg text-gray-600">Start Time</h6>
                <input
                  value={liveClassDetail.startTime}
                  onChange={(e) => {
                    setliveClassDetail({
                      ...liveClassDetail,
                      startTime: e.target.value,
                    });
                  }}
                  required
                  type="time"
                  className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                />
              </div>
            </div>
            <div className="flex gap-2 items-center mb-4">
              <div className="w-1/2">
                <h6 className="text-lg text-gray-600">Select Platform</h6>
                {user.googleConnected ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                    }}
                    className="opts"
                  >
                    <img
                      style={{
                        border:
                          platform == "gmeet" ? "3px solid dodgerblue" : "",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                      onClick={() => setPlatform("gmeet")}
                      width={90}
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/9b/Google_Meet_icon_%282020%29.svg/1024px-Google_Meet_icon_%282020%29.svg.png?20201017172050"
                      alt=""
                    />
                    <img
                      style={{
                        border:
                          platform == "zoom" ? "3px solid dodgerblue" : "",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                      onClick={() => setPlatform("zoom")}
                      height={50}
                      width={120}
                      src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg"
                      alt=""
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px",
                    }}
                    className="opts"
                  >
                    <img
                      style={{
                        border:
                          platform == "zoom" ? "3px solid dodgerblue" : "",
                        padding: "20px",
                        borderRadius: "10px",
                      }}
                      onClick={() => setPlatform("zoom")}
                      height={50}
                      width={120}
                      src="https://upload.wikimedia.org/wikipedia/commons/7/7b/Zoom_Communications_Logo.svg"
                      alt=""
                    />
                  </div>
                )}
              </div>
            </div>
            <div className=" flex gap-2 items-center ">
              <input
                type="checkbox"
                id="doubtClass"
                value={liveClassDetail.doubtClass}
                checked={liveClassDetail.doubtClass}
                onChange={() => {
                  setliveClassDetail({
                    ...liveClassDetail,
                    doubtClass: !liveClassDetail.doubtClass,
                  });
                }}
              />
              <label className="text-lg" htmlFor="doubtClass">
                This Live class also includes the doubt class
              </label>
            </div>
            <h5 className="text-gray-600 text-md">
              Live Lectures are powered by Zoom
            </h5>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Create
          </button>
        </div>
      </form>
    </>
  );
};

export default AddSessionModal;
