import React, { useState } from 'react'
import { AiOutlineClose } from 'react-icons/ai';
import { servicePost } from '../../utils/api';
import axios from 'axios';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { loadBatchId } from '../../redux/features/options/selectors';
import { setLoadingFalse, setLoadingTrue } from '../../redux/features/loading/slice';

const AddMessage = ({setOpen}) => {
    const batch = useSelector(loadBatchId); 
    const [msg, setmessage] = useState({
        title:'',
        message:''
    });
    const dispatch = useDispatch();
    const addMessage = async (e)=>{
        e.preventDefault();
        try {
          dispatch(setLoadingTrue());
            const {title, message} = msg;
            const res = await servicePost('mentor/mentor-api/v1/message', {batch, title, message}, axios.defaults.headers.common);
            setOpen(false);
            // toast.success(res?.message)   
        } catch (error) {
            toast.error(error);
        }
        finally{
          dispatch(setLoadingFalse());
        }
    }
  return (
    <>
      <form onSubmit={addMessage}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
           
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>Add Message</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setOpen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for new day
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">title</h6>
              <input
                type="text"
                required
                value={msg.title}
                onChange={(e) => {
                  setmessage({
                    ...msg,
                    title:e.target.value
                  })
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the title of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Message</h6>
              <textarea
                required
                value={msg.message}
                onChange={(e) => {
                 setmessage({
                    ...msg,
                    message: e.target.value
                 })
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Add Message
          </button>
        </div>
      </form>

    </>
  )
}

export default AddMessage