const Pill = ({ text, red = false }) => {
  const redStyle = {
    backgroundColor: "#fff2e8",
    color: "#d4380d",
    borderColor: "#ffbb96",
    borderRadius: "8px",
  };
  const greenStyle = {
    backgroundColor: "#f6ffed",
    color: "#389e0d",
    borderColor: "#b7eb8f",
    borderRadius: "8px",
  };
  return (
    <div style={red ? redStyle : greenStyle} className="p-2 cursor-pointer">
      {text}
    </div>
  );
};

export default Pill;
