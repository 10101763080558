import React, { useEffect, useState } from "react";
import { serviceDelete, serviceGet } from "../../utils/api";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loadBatchId } from "../../redux/features/options/selectors";
import toast from "react-hot-toast";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import { setLoadingFalse, setLoadingTrue } from "../../redux/features/loading/slice";
const Message = () => {
  const batch = useSelector(loadBatchId);

  const [messages, setmessages] = useState([]);
  const [pageNumber, setpageNumber] = useState(1);
  const [total, settotal] = useState(10);
  const dispatch = useDispatch();
  const getMessages = async () => {
    try {
      dispatch(setLoadingTrue());
      const {
        message,
        data: { messages },
      } = await serviceGet(
        `mentor/mentor-api/v1/message?page=${pageNumber}&batchId=${batch}`,
        axios.defaults.headers.common
        );
        
        const { data, metadata } = messages[0];
        // toast.success("messages fetched !");
        settotal(metadata[0]?.total);
        setmessages(data);
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      finally{
        dispatch(setLoadingFalse());
      }
  };
  const deleteMessage = async (id) => {
    try {
      dispatch(setLoadingTrue());
      const {
        message,
        
      } = await serviceDelete(
        `mentor/mentor-api/v1/message/${id}`,
        axios.defaults.headers.common
        );
        
        // toast.success(message);
        getMessages();
      } catch (error) {
        console.log(error);
        toast.error(error.response.data.message);
      }
      finally{
        dispatch(setLoadingFalse());
      }
  };
  useEffect(() => {
    getMessages();
  }, [pageNumber]);

  return (
    <>
      <div className="pt-12">
        {messages?.map((message, i) => {
          const currentdate = new Date(message.createdAt);
          return (
            <>
              <div key={"message- " + i}>
                <div className="bg-white w-full mt-4 rounded-lg p-6 shadow-xl">
                  <div className="flex justify-between">
                    <div className="font-semibold truncate">
                      {message?.title}
                    </div>
                    <div className="text-sm truncate">
                      {currentdate.getDate() +
                        "/" +
                        (currentdate.getMonth() + 1) +
                        "/" +
                        currentdate.getFullYear() +
                        " at " +
                        currentdate.toLocaleTimeString()}
                    </div>
                  </div>
                  <div className="truncate mt-4">{message?.message}</div>
                  <div className="flex justify-between">
                        <div className="truncate mt-4 text-gray-500 ">{"Message by: " + message?.mentor[0]?.name}</div>
                        <button onClick={()=>{deleteMessage(message._id)}} className='bg-red-500 text-white py-2 px-6 rounded-md'>Delete</button>
                     
                </div>
                </div>
              </div>
            </>
          );
        })}
        <div className="flex justify-around my-6">
          <button disabled={pageNumber<=1} onClick={()=>{setpageNumber(pageNumber-1)}} className={`bg-[#6422CD] text-white py-3 px-6 rounded-md ${pageNumber<=1 && 'cursor-not-allowed'}`}><AiOutlineArrowLeft/></button>
          <div>
              {pageNumber} of {Math.ceil(total/10)}
          </div>
          <button disabled={pageNumber>=Math.ceil(total/10)} onClick={()=>{setpageNumber(pageNumber+1)}} className={`bg-[#6422CD] text-white py-3 px-6 rounded-md ${pageNumber>=Math.ceil(total/10) && 'cursor-not-allowed'}`}><AiOutlineArrowRight/></button>
        </div>
      </div>
    </>
  );
};

export default Message;
 