import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { profileState } from "../../redux/features/auth/selectors";
import { servicePost } from "../../utils/api";
import { useLocation } from "react-router-dom";

import axios from "axios";

const Meeting = () => {
  const { mId: meetingNumber } = useParams();

  const user = useSelector(profileState);
  const userName = `${user.firstName}-${user.email}`;
  let { state } = useLocation();
  const {url} = state;
  const pass = url.split('=')[1];
  // const userEmail = ''
  const getSignature = async () => {
    try {
      const { signature } = await servicePost(
        "mentor/generateSign",
        {
          meetingNumber,
        },
        axios.defaults.headers.common
      );

      return signature;
    } catch (e) {
      console.log(e);
    }
  };
  // const signature = useMemo(() => getSignature(),[]);
  const navigate = useNavigate();

  const launchMeeting = async () => {
    const signature = await getSignature();

    window.open(
      `https://www.meet.devtown.in?sign=${signature}&type=mentor&name=${userName}&path=https://www.mentor-platform.devtown.in/dashboard&pass=${pass}`,
      // `http://localhost:3000?sign=${signature}&name=${userName}&path=http://localhost:3002/dashboard`,
      "_blank"
    );
    navigate("/dashboard");
  };
  useEffect(() => {
    launchMeeting();
  }, []);

  return (
    <div className="zm">
      ....Loading your meeting
      {/* zoom screen will be rendered here */}
    </div>
  );
};

export default Meeting;
