import axios from "axios";
import toast from "react-hot-toast";

const SERVICE_URL = "https://j66d85vpbf.execute-api.ap-south-1.amazonaws.com";

const TEST_MODE = window.location.href.includes("localhost") ? true : false;
// const TEST_MODE = false;
if (TEST_MODE)
  toast("Test mode Active !! ", {
    icon: "🧪",
  });

export const servicePost = async (path, payload, headers = null) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${
          !TEST_MODE
            ? `${SERVICE_URL}/${path}`
            : path.split("/").shift() === "mentor"
            ? `http://localhost:8081${path.slice(6)}`
            : `http://localhost:8080${path.slice(4)}`
        }`,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const serviceGet = async (path, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${
          !TEST_MODE
            ? `${SERVICE_URL}/${path}`
            : path.split("/").shift() === "mentor"
            ? `http://localhost:8081${path.slice(6)}`
            : `http://localhost:8080${path.slice(4)}`
        }`,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const servicePut = async (path, payload, headers = null) => {
  return new Promise((resolve, reject) => {
    axios
      .put(
        `${
          !TEST_MODE
            ? `${SERVICE_URL}/${path}`
            : path.split("/").shift() === "mentor"
            ? `http://localhost:8081${path.slice(6)}`
            : `http://localhost:8080${path.slice(4)}`
        }`,
        payload,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};

export const serviceDelete = async (path, headers) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        `${
          !TEST_MODE
            ? `${SERVICE_URL}/${path}`
            : path.split("/").shift() === "mentor"
            ? `http://localhost:8081${path.slice(6)}`
            : `http://localhost:8080${path.slice(4)}`
        }`,
        {
          headers: headers,
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error);
      });
  });
};
