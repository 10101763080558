import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import AddAssignmentModal from "../../components/assignmentCard/AddAssignment";
import AssignmentCard from "../../components/assignmentCard/AssignmentCard";
import Modal from "../../components/modal/modal";
import { serviceGet } from "../../utils/api";
import Navbar from "../../layout/Navbar/Navbar";
import { setLoadingFalse, setLoadingTrue } from "../../redux/features/loading/slice";
import { loadBatchId } from "../../redux/features/options/selectors";

const Assignments = () => {
    const arr = [1,2,3,4,5];
    const [assignments, setassignments] = useState([]);
    const [open, setopen] = useState(false);
    const batchId = useSelector(loadBatchId);
    const dispatch = useDispatch();
    const getAssignments = async ()=>{
        dispatch(setLoadingTrue());
        try{
        const { data } = await serviceGet(
            `mentor/mentor-api/v1/assignment?batchId=${batchId}`,
            axios.defaults.headers.common
          );
        //   console.log(data);
          setassignments(data?.assignments);
          // toast.success("Assignments Fetched");
        } catch (error) {
          toast.error(error.message);
        } finally {
          dispatch(setLoadingFalse());
        }
    }
    useEffect(() => {
      getAssignments();
    }, [batchId])
    
  return (
    <>
    <Modal state={{ open }}>
        <AddAssignmentModal
        getAssignments={getAssignments}
        batch={batchId}
        setopen={setopen}
        />
    </Modal>
      <div className="-mt-[35px] md:mt-0">
        <Navbar />
      </div>
      <hr />
      <div className="flex flex-col md:flex-row ">
        <div className="md:w-1/2 flex justify-center items-center md:h-[90vh] p-4">
          <button onClick={()=>{setopen(true)}} className="bg-btn rounded-md text-white p-2">
            Add Assignment
          </button>
        </div>
        <div className="md:w-1/2 md:overflow-y-auto md:h-[90vh]">
          {
            assignments?.map(e=>(<AssignmentCard name={e.name} description={e.description} dueDate={e.dueDate} id={e._id}/>))
          }
          {assignments?.length==0  && 
            <div className="flex justify-center items-center h-full">
                No assignments added
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default Assignments;
