import React from 'react'
const TeacherCountCard = ({ title, count,countSub, logo,color }) => {
    return (
        <div className='w-full h-full bg-white py-5 px-5 rounded-lg'>
            <div className=' flex gap-5 items-center  h-full' >
                <div className={` text-xl rounded-lg p-5 text-blue-800 bg-mainContainer`}>
                    {logo}
                </div>
                <div className='flex-col'>
                    <div className='w-full text-gray-500 text-lg '>
                        {title}
                    </div>
                    <div className='text-xl'>
                        <div className='font-bold '>
                            {count? count : 0}
                        </div>
                        <span className='text-gray-500 text-sm'>
                            {countSub}
                        </span>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default TeacherCountCard;
