import { Link } from "react-router-dom";

const AssignmentCard = ({  name, description, dueDate,id }) => {
    const d = new Date(dueDate);
  return (
    <Link to={`/assignment/${id}`}>
      <div className="bg-white flex-col gap-5 rounded-lg flex px-4 py-8 justify-around my-4 flex-wrap mx-10">
        <div>
          <strong>Name:- </strong>
          {name}
        </div>
        <div>
          <strong>Description:- </strong> {description}
        </div>
        <div>
          <strong>DueDate:- </strong> {d.getDate() + "/" + d.getMonth() + "/" + d.getFullYear()}
        </div>
       
      </div>
    </Link>
  );
};
export default AssignmentCard;
