import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import SidebarWrapper from "./components/Wrapper/SidebarWrapper";
import Dashboard from "./pages/Dashboard/Dashboard";
import Schedule from "./pages/Schedule/Schedule";
import AuthWrapper from "./components/Wrapper/AuthWrapper";
import Login from "./pages/Auth/Login";
import ForgetPass from "./pages/Auth/ForgetPass";
import ResetPass from "./pages/Auth/ResetPass";
import Syllabus from "./pages/Syllabus/Syllabus";
import ProgramDetail from "./pages/Syllabus/ProgramDetail";
import Meeting from "./pages/Meeting/Meeting";

import { Toaster } from "react-hot-toast";

import { ProtectedRoute } from "./pages/Auth/RouteProtection/protectedRoute";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { loadUser } from "./redux/features/auth/slice";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "./redux/features/loading/slice";
import { loadState } from "./redux/features/loading/selectors";
import { isReady, profileState } from "./redux/features/auth/selectors";
import { useMemo } from "react";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import { ComingSoon } from "./pages/ComingSoon/ComingSoon";
import Loader from "./components/Loader/Loader";
import GoogleConnect from "./layout/GoogleConnect";
import Profile from "./pages/Profile/Profile";
import Table from "./pages/Dashboard/Table/Table";
import Assignments from "./pages/Assignments/Assignments";
import Assignment from "./pages/Assignment/Assignment";
import Messages from "./pages/Messages/Messages";

function App() {
  const dispatch = useDispatch();
  const load = useSelector(loadState);
  const ready = useSelector(isReady);
  const [isLoading, setLoading] = useState(false);
  const user = async () => {
    dispatch(setLoadingTrue());
    await dispatch(loadUser());
    dispatch(setLoadingFalse());
  };

  const client = useMemo(() => ZoomMtgEmbedded.createClient(), []);

  useEffect(() => {
    user();
  }, []);

  useEffect(() => {
    setLoading(load);
  }, [load]);

  return (
    <>
      <div>
        <Toaster />
      </div>
      <div
        className={`bg-sky-300 bg-opacity-30 ${isLoading ? "flex" : "hidden"}`}
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          justifyContent: "center",
          alignItems: "center",
          zIndex: "10",
        }}
      >
        <Loader />
      </div>
      {/* <GoogleConnect/> */}
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SidebarWrapper />
              </ProtectedRoute>
            }
          >
            {/*routing level-1*/}
            <Route
              index
              element={
                <ProtectedRoute>
                  <Navigate to="/dashboard" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/attendance"
              element={
                <ProtectedRoute>
                  <Table />
                </ProtectedRoute>
              }
            />
            <Route
              path="/schedule"
              element={
                <ProtectedRoute>
                  <Schedule />
                </ProtectedRoute>
              }
            />
            <Route
              path="/syllabus"
              element={
                <ProtectedRoute>
                  <Syllabus />
                </ProtectedRoute>
              }
            >
              {/*routing level-2*/}
              <Route
                index
                element={
                  <ProtectedRoute>
                    <h1 className="text-xl mt-1 border-t-gray-300 border-t-2">
                      Please select one day from any week
                    </h1>
                  </ProtectedRoute>
                }
              />
              <Route
                path="day"
                element={
                  <ProtectedRoute>
                    <ProgramDetail />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              path="/meeting/:mId"
              element={
                <ProtectedRoute>
                  <Meeting client={client} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assignments"
              element={
                <ProtectedRoute>
                  <Assignments />
                </ProtectedRoute>
              }
            />
            <Route
              path="/assignment/:id"
              element={
                <ProtectedRoute>
                  <Assignment />
                </ProtectedRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />
            <Route
              path="/me"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/auth" element={<AuthWrapper />}>
            {/*routing level-1*/}
            <Route index element={<Login />} />
            <Route path="login" element={<Login />} />
            <Route path="forgot-password" element={<ForgetPass />} />
            <Route path="reset-password/:token" element={<ResetPass />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
