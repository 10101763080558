import axios from "axios";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { setLoadingFalse, setLoadingTrue } from "../../redux/features/loading/slice";
import { serviceDelete } from "../../utils/api";

const DeleteAssignment = ({ setOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {id} = useParams();
    const handleClick = async () => {
       
        dispatch(setLoadingTrue());
        setOpen(false);
       
        try {
          const { data } = await serviceDelete(
            `mentor/mentor-api/v1/assignment?id=${id}`,
            axios.defaults.headers.common
          );
          // toast.success("Assignment Deleted");
          navigate("/assignments");
        } catch (error) {
          toast.error(error.message);
        } finally {
          dispatch(setLoadingFalse());
        }
       
    }
  return (
    <>
      <div className="pt-6 px-6 bg-white w-full">
        <div className="flex gap-5 items-center mb-4">
         
         
            <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
              <h5>Do you want to Delete this Assignment ?</h5>
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
                size="1.4rem"
              />
            </div>
         
          
        </div>
        <hr />

        <div className="w-full flex gap-8 justify-center p-4 ">
          <button
          onClick={handleClick}
            className="bg-purple-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            Yes
          </button>
          <button
             onClick={() => {
                setOpen(false);
              }}
            className="bg-red-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default DeleteAssignment;
