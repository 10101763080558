import axios from "axios";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setLoadingFalse, setLoadingTrue } from "../../redux/features/loading/slice";
import { serviceDelete } from "../../utils/api";

const ResourceCard = ({ url, title, description,id, getResources }) => {
  const dispatch = useDispatch();
  const deleteResource= async()=>{
    try {
      dispatch(setLoadingTrue());
      const { message } = await serviceDelete(
        `mentor/mentor-api/v1/resource?resourceId=${id}`,
        axios.defaults.headers.common
      );
      await getResources();
      // toast.success(message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
    finally{
      dispatch(setLoadingFalse());
    }
  }
  return (
    <div className="bg-white flex-col gap-5 rounded-lg flex px-4 py-8 justify-around my-4 flex-wrap mx-10 ">
      <div className="flex justify-between items-center flex-wrap">
        <div className="md:w-8/12">
          <strong>Title:- </strong>
          {title}
        </div>
        <div >
          <button onClick={deleteResource} className="bg-red-400 px-4 py-2 rounded-lg text-white cursor-pointer">
            Delete
          </button>
        </div>
      </div>
      <div>
        <strong>Description:- </strong> {description}
      </div>
      <div className="break-words w-full">
        <strong>URL:- </strong>
        {url}
      </div>
    </div>
  );
};
export default ResourceCard;
