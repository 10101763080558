import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/features/auth/slice";

const ConfirmationLogout = ({ setOpen }) => {
   
    const dispatch = useDispatch();

    const Logout = () => {
        console.log('logging out')
        dispatch(logout())
    }
  return (
    <>
      <div className="pt-6 px-6 bg-white w-full">
        <div className="flex gap-5 items-center mb-4">
         
         
            <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
              <h5>Do you want to Logout ?</h5>
              <AiOutlineClose
                className="cursor-pointer"
                onClick={() => {
                  setOpen(false);
                }}
                size="1.4rem"
              />
            </div>
         
          
        </div>
        <hr />

        <div className="w-full flex gap-8 justify-center p-4 ">
          <button
          onClick={Logout}
            className="bg-purple-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            Yes
          </button>
          <button
             onClick={() => {
                setOpen(false);
              }}
            className="bg-red-600 p-3 px-5 font-semibold text-white rounded-full "
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default ConfirmationLogout;
