import React, { useState } from 'react';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
//logos
import {MdDashboard} from "react-icons/md";
import {BsFillCalendar2Fill} from "react-icons/bs";
import {BiMessageDots} from "react-icons/bi";
import {FaShapes,FaBook} from "react-icons/fa";
import {RiLogoutBoxLine} from "react-icons/ri";
import Logo from "../../assets/images/Logo.svg";
import Dropdown from './Dropdown';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { authState } from '../../redux/features/auth/selectors';
import {logout} from '../../redux/features/auth/slice'
import Modal from '../modal/modal';
import ConfirmationLogout from '../confirmation/ConfirmationLogout';
import { loadBatchId } from '../../redux/features/options/selectors';
import { setDayId } from '../../redux/features/options/slice';
const mentorNavbarConfig= [
    {
        title: 'Dashboard',
        url: '/dashboard',
        icon: <MdDashboard />
      },
      {
        title: 'My Schedule',
        url: '/schedule',
        icon: <BsFillCalendar2Fill />
      },
      {
        title: 'Syllabus',
        url: '/syllabus',
        icon: < FaBook/>
      },
      {
        title: 'Assignments',
        url: '/assignments',
        icon: <FaShapes />
      },
    //   {
    //     title: 'Messages',
    //     url: '/messages',
    //     icon: <BiMessageDots />
    //   }
];

const Sidebar = () => {
    const location = useLocation();
    const id = useSelector(loadBatchId);
    const url=location.pathname.split('/')[1];
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const isAuthenticated = useSelector(authState)
    const [open, setopen] = useState(false);
    useEffect(()=>{
        if (!isAuthenticated) navigate('/auth')
    },[isAuthenticated,navigate])

  
    return (
        <>
         <Modal state={{open}}>
                <ConfirmationLogout setOpen={setopen} />
            </Modal>
            <div  className='h-full  text-white  '>
                 <div className="w-full  py-5 pt-0 h-full  text-center ">
                    <div className="flex justify-center align-center my-5 " >
                       <img src={Logo} alt="logo" />
                    </div>
                    <div className='my-2 px-4  w-full'>
                        <Dropdown/>
                    </div>
                    <div  className="  w-full flex flex-col justify-between   ">
                        <div onClick={()=>{
                dispatch(setDayId(null));
            }} >
                            {
                                mentorNavbarConfig.map(page => {
                                    return (
                                        <div key={page.title} className={` w-full  rounded-md ${('/'+url)===page.url && 'bg-selectedLink'}  `}>
                                            <Link to={page.url  } className={`flex gap-5 p-5 pl-4 items-center `}>
                                                 {page.icon}
                                                <p className="text-xl">{page.title}</p>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className=" w-full p-5 rounded-md pl-4">
                            <button className="flex w-full gap-5 items-center" onClick={()=>{
                                setopen(true);
                            }}>
                                <RiLogoutBoxLine />
                                <p className="text-xl">Logout</p>
                            </button>
                        </div>

                    </div> 
                </div>
            </div>
        </>
    );
};

export default Sidebar;
