import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'
import SyllabusSelector from '../../components/SyllabusSelector/SyllabusSelector'
import Navbar from '../../layout/Navbar/Navbar'
import { profileState } from '../../redux/features/auth/selectors'

const Syllabus = () => {
  const navigate = useNavigate();
  const profile = useSelector(profileState);
//   useEffect(() => {
//     if (!profile?.profileComplete) {
//         navigate("/me");
//         toast("You need to complete your profile before proceeding");
//       }
// }, [])
  return (
    <>
    <div className='tablet:hidden'>
      <Navbar/>
    </div>
     <div className='w-full flex flex-col tablet:flex-row'>
       <div className='w-full tablet:w-1/3'>
          <div className='w-full border-r-2 border-gray-300 tablet:h-screen p-4 overflow-y-auto h-max ' >
            <SyllabusSelector/>
          </div>
       </div>
        <div className='w-full tablet:w-2/3  px-2 md:px-10 overflow-y-auto max-h-screen'>
          <div className='hidden tablet:block'>
          <Navbar/>
          </div>
          <Outlet/>
        </div>
     </div>
    </>
  )
}

export default Syllabus