import { servicePost } from "../../../utils/api";

import { deleteHeader, setHeader } from "../../../utils/header";
import toast from "react-hot-toast";
class Auth{

    constructor(){
        const token = localStorage.getItem('token');
        if(!token)
            this.authenticated = false;
        else
            this.authenticated = true;
    }

    async login(callBack, values){
        try {
            const res = await servicePost('auth/auth-api/v1/login?type=mentor', { ...values })
            const { data: { token }, message } = res
            if (token != null) {
                localStorage.setItem('token', token)
                // axios.defaults.headers.common['auth'] = `bearer ${token}`;
                setHeader('auth', `bearer ${token}`);
                this.authenticated = true;
            } else {
                // delete axios.defaults.headers.common['auth'];
                deleteHeader('auth', `bearer ${token}`);
                console.log(message)
            }
            console.log(res)
            callBack();
        } catch (error) {
            // delete axios.defaults.headers.common['auth'];
            deleteHeader('auth');
            console.log(error)
        }
    }

    async forgotPassword(callBack, values){
        try {
            const res = await servicePost('auth/auth-api/v1/forgot-password?type=mentor', { ...values,callbackUrl:"https://mentor-platform.devtown.in" })
            const { success, message } = res
            if (success) {
                //    return toast.success(message);
                } 
                else{
                    const [err] = res.data.errors
                    console.log(err)
                     callBack();
                     return err.param === "token"
                       ? toast.error(
                           "Your token has expired !! "
                         )
                       : toast.error("Error");
                }
            callBack();
        } catch (error) {
            console.log(error.message)
        }
    }

    async resetPassword(callBack, values, token){
        if(token == null)
            callBack();
        else
            try {
                const res = await servicePost(`auth/auth-api/v1/reset-password?type=mentor&token=${token}`, { ...values })
                const { success, message } = res
                if (success != false) {
                    console.log(message);
                } else {
                    console.log(message);
                }
                callBack();
            } catch (error) {
                console.log(error.message)
            }
    }

    isAuthenticated(){
        return this.authenticated;
    }
    
}

export default new Auth();