import {
    createSlice,
} from "@reduxjs/toolkit";


const initialState = {
    isLoading:false
};

const loadingSlice = createSlice({
    name: "loading",
    initialState,
    reducers: {
        setLoadingTrue(state, action) {
            state.isLoading = true;
        },
        setLoadingFalse(state, action) {
            state.isLoading = false;
        }
    }
  
});

export const { setLoadingTrue, setLoadingFalse } = loadingSlice.actions;


export default loadingSlice.reducer;
