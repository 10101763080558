import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { serviceGet } from "../../utils/api";
import axios from 'axios';
import { setHeader } from "../../utils/header";
import { useDispatch, useSelector } from "react-redux";
import { setLoadingTrue, setLoadingFalse } from "../../redux/features/loading/slice";
import { setBatchId } from "../../redux/features/options/slice";
import { loadBatchId } from "../../redux/features/options/selectors";

const Index = () => {
    const [isList, setIsList] = useState(false);
    const id=useSelector(loadBatchId);
    const location = useLocation();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    
    const getBatches = async () => {
        try {
            const token = localStorage.getItem('token');
            // axios.defaults.headers.common['auth'] = `bearer ${token}`;
            setHeader('auth', `bearer ${token}`);
            dispatch(setLoadingTrue());
            const res = await serviceGet('mentor/mentor-api/v1/batches', axios.defaults.headers.common);
            dispatch(setLoadingFalse());
            const { data:{batches: {overview}}} = res;
            // navigate(`/dashboard/${overview[0]?._id}`);
            setData(overview);
            // console.log(overview[0]);
            dispatch(setBatchId(overview[0]?._id))
        } catch (error) {
            dispatch(setLoadingFalse());
            console.log(error.message);
        }
    }

    useEffect(()=>{
        if(data.length !== 0 && data?.filter((batch)=>{
            if(batch?._id === id)
                return batch;
        }).length === 0 && location.pathname.includes("meeting") != true){
            console.log('navigating user')
            //  navigate(`/dashboard/${data[0]?._id}`)
        }
           
    })

    useEffect(()=>{
        getBatches();
    }, [])

    return (
      <div>
        <div
          onClick={() => setIsList(!isList)}
          className="w-full p-4 shadow rounded bg-white text-sm font-medium leading-none text-gray-800 flex items-center justify-between cursor-pointer"
        >
          {data?.map((batch) => {
            if (batch?._id === id) return batch?.name;
          })}
          {data.length === 0
            ? "No batch assigned"
            : ''}
            {id===null||id==='undefined'?'Select a Batch':''}
          <div>
            {isList ? (
              <div>
                <svg
                  width={10}
                  height={6}
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.00016 0.666664L9.66683 5.33333L0.333496 5.33333L5.00016 0.666664Z"
                    fill="#1F2937"
                  />
                </svg>
              </div>
            ) : (
              <div>
                <svg
                  width={10}
                  height={6}
                  viewBox="0 0 10 6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.00016 5.33333L0.333496 0.666664H9.66683L5.00016 5.33333Z"
                    fill="#1F2937"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
        {isList && (
          <div
            className="w-full  mt-2 p-4 bg-white shadow rounded transition ease-in-out delay-300 origin-top-right"
            onClick={() => setIsList(!isList)}
          >
            {data?.map((batch) => {
              return (
                <div className="w-full flex text-sm my-4 font-semibold ml-2 text-gray-800 cursor-pointer" onClick={()=>{
                  dispatch(setBatchId(batch?._id))
                }}>
               
                  
                
                  {batch?.name}
                
                </div>
              );
            })}
          </div>
        )}
        <style>
          {` .checkbox:checked + .check-icon {
                display: flex;
            }`}
        </style>
      </div>
    );
};
export default Index;
