import React, { useState } from "react";
import { BsFillCalendar2Fill } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import { serviceGet, servicePost } from "../../utils/api";
import { useDispatch, useSelector } from "react-redux";
import { profileState } from "../../redux/features/auth/selectors";
import axios from "axios";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../redux/features/loading/slice";
import toast from "react-hot-toast";

const AddVideoModal = ({ setopen, day, batch, getSessions, setRerender }) => {
  const [videoDetail, setVideoDetail] = useState({
    title: null,
    description: null,
    url: null,
  });

  // // const user = useSelector(profileState);;
  const dispatch = useDispatch();

  const handleClick = async (e) => {
    e.preventDefault();
    dispatch(setLoadingTrue());
    setRerender((rerender) => !rerender);
    setopen(false);
    try {
      const { title, description, url } = videoDetail;
      const { data } = await serviceGet(
        `mentor/mentor-api/v1/addvideo?title=${title}&description=${description}&destinationUrl=${url}&batch=${batch}&day=${day}&type=url`,
        axios.defaults.headers.common
      );
      // toast.success("Video Uploaded");
      console.log(data);
    } catch (error) {
      toast.error(error.message);
    } finally {
      dispatch(setLoadingFalse());
    }
  };
  return (
    <>
      <form onSubmit={handleClick}>
        <div className="pt-6 px-6 bg-white w-full ">
          <div className="flex gap-5 items-center mb-4">
            <div className="bg-blue-100 p-2 text-blue-800 font-xl rounded-md">
              <BsFillCalendar2Fill />
            </div>
            <div className="w-full ">
              <div className="w-full text-gray-600 font-xl font-extrabold flex justify-between items-center">
                <h5>New Video Lecture</h5>
                <AiOutlineClose
                  className="cursor-pointer"
                  onClick={() => {
                    setopen(false);
                  }}
                  size="1.4rem"
                />
              </div>
              <div className=" text-gray-600">
                Please provide details for the Video lecture
              </div>
            </div>
          </div>
          <hr />
          <div className="my-2">
            <div className="mb-4">
              <h6 className="text-lg text-gray-600">Title</h6>
              <input
                type="text"
                required
                value={videoDetail.title}
                onChange={(e) => {
                  setVideoDetail({
                    ...videoDetail,
                    title: e.target.value,
                  });
                }}
                className="w-full bg-gray-100 rounded-lg p-2 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the title of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">Description</h6>
              <textarea
                required
                value={videoDetail.description}
                onChange={(e) => {
                  setVideoDetail({
                    ...videoDetail,
                    description: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the description of the lecture"
              />
            </div>
            <div className="mb-2">
              <h6 className="text-lg text-gray-600">URL</h6>
              <input
                type="url"
                required
                value={videoDetail.url}
                onChange={(e) => {
                  setVideoDetail({
                    ...videoDetail,
                    url: e.target.value,
                  });
                }}
                className="w-full rounded-lg p-2 bg-gray-100 focus:outline focus:outline-2 focus:outline-blue-500"
                placeholder="Enter the URL of the video lecture"
              />
            </div>
          </div>
        </div>
        <div className="w-full bg-gray-100 p-10 relative">
          <button
            type="submit"
            className="bg-purple-600 p-3 text-white rounded-full absolute right-5 top-4"
          >
            Create
          </button>
        </div>
      </form>
    </>
  );
};

export default AddVideoModal;
