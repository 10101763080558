import { useNavigate } from "react-router-dom";
import auth from "./RouteProtection/auth";
import { useParams } from "react-router-dom";
import img from "../../assets/images/ICON.svg";
import { useState } from "react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import toast from "react-hot-toast";
export default function ForgetPass() {
  const navigate = useNavigate();
  const { token } = useParams();
  const [showPass, setshowPass] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form_data = new FormData(event.target);
    let values = {};
    form_data.forEach(function (value, key) {
      values[key] = value;
    });

    if (values.password === values.confirmPassword){
      auth.resetPassword(() => navigate("/"), values, token);
    }
    else{
      toast.error("Password and Confirm password are not same.")
    }
  };
  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html class="h-full bg-gray-50">
        <body class="h-full">
        ```
      */}
      <div className="min-h-full h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img className="mx-auto h-12 w-auto" src={img} alt="Workflow" />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Enter New Password !
            </h2>
          </div>
          {/* <p className="text-gray-500 font-bold" >Sign in with</p>
          <div className="flex justify-around" >
              <button className="border py-1 border-gray-300 w-1/4 rounded-lg">fb</button>
              <button className="border py-1 border-gray-300 w-1/4 rounded-lg">github</button>
              <button className="border py-1 border-gray-300 w-1/4 rounded-lg">google</button>
          </div>
          <p className="text-gray-500 text-center " >Or continue with</p> */}
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="mb-4">
                <label htmlFor="password" className="sr-only">
                  New Password
                </label>
                <div className="flex items-center bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 border border-gray-300">
                  <input
                    id="password"
                    name="password"
                    type={!showPass ? "password" : "text"}
                    required
                    className="appearance-none rounded-none relative block w-full px-3 py-2   placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10m"
                    placeholder="New Password"
                  />
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      setshowPass(!showPass);
                    }}
                  >
                    {showPass ? (
                      <AiOutlineEye size="1.4rem" />
                    ) : (
                      <AiOutlineEyeInvisible size="1.4rem" />
                    )}
                  </div>
                </div>
              </div>
              <div>
                <label htmlFor="confirmPassword" className="sr-only">
                  Confirm New Password
                </label>
                <div className="flex items-center bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 border border-gray-300">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={!showPass?"password":"text"}
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border  placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 "
                  placeholder="Confirm New Password"
                />
                 <div
                    className="cursor-pointer"
                    onClick={() => {
                      setshowPass(!showPass);
                    }}
                  >
                    {showPass ? (
                      <AiOutlineEye size="1.4rem" />
                    ) : (
                      <AiOutlineEyeInvisible size="1.4rem" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {/* <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span> */}
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
