import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    start : new Date(),
    end : new Date()
}

const dateSlice = createSlice({
    name: "date",
    initialState,
    reducers: {
        setStartDate(state, action) {
            state.start = action.payload;
        },
        setEndDate(state, action) {
            state.end = action.payload;
        }
    }
})


export const { setStartDate, setEndDate } = dateSlice.actions;


export default dateSlice.reducer;
