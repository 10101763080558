import React from "react";
import { useSelector } from "react-redux";
import {  Navigate } from "react-router-dom";
import { authState,isReady } from "../../../redux/features/auth/selectors";

export const ProtectedRoute = (props) => {
    const isAuthenticated = useSelector(authState)
    const ready = useSelector(isReady)
    if(ready){
        if (isAuthenticated) {
          return props.children;
        } else {
          return (
            <Navigate
              replace
              to={{
                pathname: "/auth",
              }}
            />
          );
        }
    }else{
        return null
    }
    
};
