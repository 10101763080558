import React from 'react'
import toast from 'react-hot-toast'
import { AiFillCopy } from 'react-icons/ai'

const AboutProgram = ({branchName,description}) => {
  const desc = () => {
    return{
      __html:description
    }
  }
  return (
    <div className='px-2 py-8 border-b-2 border-gray-300'>
      {
        branchName &&
        <div className='mb-4 '>
        <div className='text-md font-semibold '>
          Use this Command to switch to this day's branch:
        </div>
        <div className='flex justify-between items-center'>
          git fetch && git checkout -b {branchName} 
          <button onClick={()=>{
            navigator.clipboard.writeText(`git fetch && git checkout -b ${branchName}`);
            toast.success('Copied to clipboard');
          }}>
            <AiFillCopy/>
          </button>
        </div>
        </div>
      }
        <div className='text-md' dangerouslySetInnerHTML={desc()}>
        </div>
    </div>
  )
}

export default AboutProgram