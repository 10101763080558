import React from 'react'
import {BsThreeDotsVertical} from "react-icons/bs"
import {MdOutlineLiveTv} from "react-icons/md";
const TeacherClassCard = ({session}) => {
   
    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  const dt = new Date(new Date(session?.date).toLocaleString('en-US', { timeZone: 'UTC' }))
  const dt_e = new Date(new Date(session?.date).toLocaleString('en-US', { timeZone: 'UTC' }))
  dt_e.setHours(dt.getHours()+2)
    return (
<>

<div className='rounded-md p-4 mb-4 bg-white cursor-pointer'>
            <div className='flex gap-5 w-full items-center ' >
                <div className='text-xl rounded-lg p-5 text-blue-800 bg-mainContainer'>
                    <MdOutlineLiveTv/>
                </div>
                <div className='w-full'>
                    <div className='w-full text-lg flex gap-10 flex-start justify-between'>
                        <h5 className='text-lg'>
                            <strong>
                                {session?.topic}
                            </strong>
                        </h5>
                        {new Date() <= dt_e ?(<BsThreeDotsVertical className='cursor-pointer'/>):"Expired"}
                    </div>
                    <div className='text-gray-400'>Scheduled on {dt.toDateString()} at {dt.toLocaleTimeString()}</div>
                </div>
            </div>
           
        </div>
</>
    )
}

export default TeacherClassCard
