import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {AiOutlineEye,AiOutlineEyeInvisible} from "react-icons/ai"
import { useDispatch, useSelector } from "react-redux";
import {  login } from "../../redux/features/auth/slice";
import {  setLoadingFalse, setLoadingTrue } from "../../redux/features/loading/slice";
import { authState } from "../../redux/features/auth/selectors";
import img from "../../assets/images/ICON.svg";

export default function Login() {
  const [showPass, setshowPass] = useState(false)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSubmit = async(event) =>{
    event.preventDefault();
    const form_data = new FormData(event.target);
    let values = {};
    form_data.forEach(function (value, key) {
        values[key] = value;
    });
    // dispatch for login
    dispatch(setLoadingTrue())
    await dispatch(login(values))
    dispatch(setLoadingFalse())
  }

  const isAuthenticated = useSelector(authState)

  useEffect(()=>{
    if (isAuthenticated) navigate('/');
  },[isAuthenticated, navigate])


  return (
    <>
      <div className=" h-screen   flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md bg-red-100 p-10 rounded-lg w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={img}
              alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to your account</h2>
            
          </div>
          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            <div className="rounded-md shadow-sm -space-y-px">
              <div className="mb-6">
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-lg focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <div className="flex items-center bg-white focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 border border-gray-300">
                <input
                  id="password"
                  name="password"
                  type={!showPass?"password":"text"}
                  
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 outline-none placeholder-gray-500 text-gray-900 rounded-lg  sm:text-sm"
                  placeholder="Password"
                />
                <div className="cursor-pointer" onClick={()=>{setshowPass(!showPass)}} >
                {showPass?<AiOutlineEye size="1.4rem"/>:<AiOutlineEyeInvisible size="1.4rem"/>}

                </div>
                </div>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                />
                <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link to="/auth/forgot-password" className="font-medium text-indigo-600 hover:text-indigo-500">
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}